import React, { useCallback, useEffect, useState, useRef } from 'react'
import { InputSearch } from '@produits-internes-oss/design-system-components'
import { useTranslation } from 'react-i18next'
import pictoCross from '../../../assets/images/cross.svg'
import { useProjectSalesContext } from '../../context/ProjectSale/ProjectSalesContext'

export const ProjectSelectSkillsActivity = ({ compact = false, activity = null, name = null, updateActivity, isEditable = true }) => {
  const {
    skills,
    selectedSkills,
    updateSelectedSkills,
    addSkillActivity,
    removeSkillFromActivity,
    formStaffing,
  } = useProjectSalesContext()

  const { t } = useTranslation()

  const [modeAddSkill, setModeAddSkill] = useState(false)
  const inputSearchRef = useRef()

  const listSkillInSearch = (search) => {
    if (search.length <= 0) {
      updateSelectedSkills([])
      return []
    }
    const skillsAfterSearch = skills.filter((skill) => skill.label.toLowerCase().includes(search.toLowerCase()))
    updateSelectedSkills(skillsAfterSearch)
    return [...skillsAfterSearch]
  }

  const listSkillsInActivity = useCallback(() => {
    let skillsInActivityWithLabel = []

    if (!isEditable) {
      if (activity && activity.skills) {
        activity.skills.forEach((skill) => {
          skills.find((skillDefault) => {
            if (skillDefault.id === skill) {
              skillsInActivityWithLabel = [...skillsInActivityWithLabel, { id: skillDefault.id, label: skillDefault.label }]
              return null
            }
            return null
          })
        })
      }
    } else if (!compact && formStaffing['skill_ids']) {
      formStaffing['skill_ids'].forEach((skill) => {
        const findSkillInAllSkill = skills.find((skillDefault) => skillDefault.id === skill)
        skillsInActivityWithLabel = [...skillsInActivityWithLabel, findSkillInAllSkill]
      })
    } else if (compact && activity && activity.skills) {
      activity.skills.forEach((skill) => {
        const findSkillInAllSkill = skills.find((skillDefault) => skillDefault.id === skill.id)
        skillsInActivityWithLabel = [...skillsInActivityWithLabel, findSkillInAllSkill]
      })
    } else {
      skillsInActivityWithLabel = []
    }

    return (
      <div className="skill-in-activity">
        {
            compact && isEditable
              ? (
                <div className="button-list">
                  <button
                    type="button"
                    className="mode-add-skill"
                    onClick={() => {
                      setModeAddSkill(!modeAddSkill)
                    }}
                  >
                    {t('project.staffing.add_skill')}
                  </button>
                </div>
              )
              : null
          }
        <ul>
          {
            skillsInActivityWithLabel.map((skill) => (
              <li key={`${skill.id}-${skill.label}`}>
                {skill.label}
                {
                  isEditable
                    ? (
                      <button
                        type="button"
                        className="button-delete-skill"
                        onClick={() => {
                          if (compact) {
                            updateActivity(name, 'skill_ids', activity.skills.map((skillInActivity) => skillInActivity.id).filter((skillId) => skillId !== skill.id))
                          } else {
                            removeSkillFromActivity(skill.id)
                          }
                        }}
                      >
                        <img alt="cross" src={pictoCross} />
                      </button>
                    )
                    : null
              }
              </li>
            ))
          }
        </ul>

      </div>
    )
  }, [formStaffing['skill_ids']])

  useEffect(() => {
    const divInput = inputSearchRef.current
    if (divInput) {
      const divInputText = divInput.querySelector('#skill-search')
      divInputText.value = ''
    }
  }, [formStaffing['skill_ids']])

  return (
    <div className="form-item">
      <div className="form-control form-search">
        {
          compact
          && modeAddSkill
          && (
            <div className="button-list">
              <button
                type="button"
                className="mode-add-skill"
                onClick={() => {
                  setModeAddSkill(!modeAddSkill)
                }}
              >
                {t('project.staffing.back')}
              </button>
              <a className="page-list-skills" href="https://askbob.octo.tools/skills" target="_blank" rel="noreferrer">{t('project.staffing.form.expertise')}</a>
            </div>
          )
        }

        { (!compact || (compact && modeAddSkill))
          && (
          <div ref={inputSearchRef}>
            <InputSearch
              className="form-control"
              skin="light"
              name="skill-search"
              title={<a className="link-skills-page" href="https://askbob.octo.tools/skills" target="_blank" rel="noreferrer">{t('project.staffing.form.expertise')}</a>}
              required={false}
              onSearch={(search) => listSkillInSearch(search).map((skill) => skill.label)}
              onChange={({ key }) => {
                let skillSelect = null
                if (key !== null) {
                  skillSelect = selectedSkills[key]
                  if (skillSelect !== null) {
                    if (compact) {
                      updateActivity(name, 'skill_ids', [...activity.skills.map((skill) => skill.id), skillSelect.id])
                    } else {
                      addSkillActivity(skillSelect.id)
                    }
                  }
                }
              }}
            />
          </div>
          )}
        {
          !modeAddSkill
            ? listSkillsInActivity()
            : null
        }
      </div>
    </div>
  )
}
