import styled from 'styled-components'

export const ProjectInlineStaffingFormStyled = styled.div`
  &.inline-staffing-form {
    margin-top: 2rem;
    padding: 2.4rem;
    border-width: 0.1rem;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.betterway.primary[20]};
    border-radius: 2rem;
    background-color: ${({ theme }) => theme.colors.betterway.primary[5]};

    .form-content {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      align-items: start;

      .octo.hard {
        width: auto;
      }

      .full-name {
        min-width: 30rem !important;
      }

      .validate {
        flex-direction: row;
        align-items: flex-end;
      }

      .form-item {
        flex-grow: 1;
      }

      .form-control {
        flex: 1 1 auto;
        min-width: 150px;

        .control {
          width: 100%;

          button,
          input {
            &:focus-visible {
              border-width: 0.3rem;
              border-style: solid;
              border-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
            }
          }
        }
      }

      .form-search {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 20em;
        height: auto;

        .link-skills-page {
          text-decoration: underline !important;
          cursor: pointer !important;

          :focus {
            color: ${({ theme }) => theme.colors.betterway.secondary[100]};
          }

          :hover {
            color: ${({ theme }) => theme.colors.betterway.secondary[100]};
          }
        }

        .form-control-search-skill {
          &.open {
            .control {
              ::before {
                border-bottom: none !important;
                border-bottom-right-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
              }
            }
          }
        }

        .list-skills {
          position: absolute;
          top: 4.1em;
          z-index: 100;
          display: flex;
          flex-direction: column;
          overflow: scroll;
          width: 100%;
          max-height: 10em;
          padding: 1em;
          padding-top: 0.6em;
          border: solid black 0.0625em;
          border-width: 0.0625em;
          border-radius: 0 0 1.25em 1.25em;
          background-color: white;

          button {
            border: none;
            background-color: transparent;

            :hover {
              font-weight: bold;
            }
          }
        }

        .skill-in-activity {
          width: 100%;

          ul {
            display: flex;
            flex-flow: row wrap;
            gap: 0.4em;
            width: 100%;
            margin-top: 0.8em;

            li {
              display: flex;
              gap: 0.2em;
              align-items: center;
              justify-content: center;
              width: auto;
              padding: 0.1em;
              font-size: 0.8em;

              span {
                width: fit-content;
              }

              .button-delete-skill {
                display: flex;
                width: 1.6em;
                border: solid black;
                border-color: ${({ theme }) => theme.colors.betterway.secondary[20]};
                border-radius: 5px;
                cursor: pointer;

                :hover {
                  background-color: ${({ theme }) => theme.colors.betterway.secondary[20]};
                }

                :focus {
                  background-color: ${({ theme }) => theme.colors.betterway.secondary[20]};
                }
              }
            }
          }
        }
      }
    }
  }
`
