import React from 'react'
import { useTranslation } from 'react-i18next'
import { useExpensesContext } from '../../context/Expenses/ExpensesContext'
import ExpensesDetailsStyled from './ExpensesDetails.styled'
import modifyIcon from '../../../assets/images/iconsModify.svg'
import destroyIcon from '../../../assets/images/destroyEmpty.svg'
import pictoDoubleArrow from '../../../assets/images/doubleArrow-cropped.svg'
import pictoDuplicate from '../../../assets/images/copy2.svg'

export default function ExpensesDetails({ className, expenses }) {
  const { t } = useTranslation()
  const { options, fetchEditExpense, fetchDestroyExpense, canEditAlreadySentExpenses, fetchUpdateExpenseStatus, fetchDuplicateExpense } = useExpensesContext()

  return (
    <ExpensesDetailsStyled className={className}>
      <div className="table">
        <table>
          <thead>
            <tr>
              <th>Statut</th>
              <th>Type</th>
              <th>Description</th>
              <th>Date</th>
              <th>Montant</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {expenses && expenses.map((expense) => {
              const id = expense.getValue('id')
              const status = expense.getValue('status')
              const date = new Date(expense.getValue('day'))
              const type = expense.getValue('type')
              const currency = options.countries.find((country) => country.name === expense.getValue('country_id'))?.currency
              const value = `${parseFloat(expense.getValue('value')).toFixed(2)}${currency}`
              const description = expense.getValue('label')
              const toSend = expense.getValue('to_send')

              const synchronizeButton = () => (
                canEditAlreadySentExpenses && ['processing', 'processed'].includes(status) && type !== 'TeleworkExpense'
                  ? (
                    <button type="button" className="update-status-button" onClick={() => fetchUpdateExpenseStatus(id)} title="Changer le status">
                      <img className="synchronize" alt="" src={pictoDoubleArrow} />
                    </button>
                  ) : ''
              )

              const updateButton = () => (
                (toSend || canEditAlreadySentExpenses) && type !== 'TeleworkExpense'
                  ? (
                    <button type="button" className="modify-button" onClick={() => fetchEditExpense(id)} title="Modifier">
                      <img className="modify" alt="" src={modifyIcon} />
                    </button>
                  ) : ''
              )

              const deleteButton = () => (
                toSend
                  ? (
                    <button type="button" className="delete-button" onClick={() => fetchDestroyExpense(id)} title="Supprimer">
                      <img className="destroy" alt="" src={destroyIcon} />
                    </button>
                  ) : ''
              )

              const duplicateButton = () => (
                type !== 'TeleworkExpense'
                  ? (
                    <button type="button" className="duplicate-button" onClick={() => fetchDuplicateExpense(id)} title="Dupliquer">
                      <img className="duplicate" alt="" src={pictoDuplicate} />
                    </button>
                  ) : ''
              )

              return (
                <tr key={id} className="border">
                  <td className="tab-cell">
                    <div className={['header-status-icon', status].join(' ')} title={t(`expenses.status.${status}`)} />
                  </td>
                  <td className="tab-cell">{t(`expenses.categories.type.${type}`)}</td>
                  <td className="tab-cell description">{description}</td>
                  <td className="tab-cell">
                    {`${date.getDate() < 9 ? `0${date.getDate()}` : date.getDate()}/${date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}`}
                  </td>
                  <td className="tab-cell">{value}</td>
                  <td className="tab-cell actions-cell">
                    <div className="actions-buttons">
                      {synchronizeButton()}
                      {updateButton()}
                      {deleteButton()}
                      {duplicateButton()}
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </ExpensesDetailsStyled>
  )
}
