import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;

  .table {
    width: 100%;
    border: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[20]};
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.colors.betterway.white};
    table-layout: auto;

    table {
      width: 100%;
    }

    th,
    td {
      padding: 1rem;
      border: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[20]};
      text-align: left;
      white-space: normal;
      word-wrap: break-word;
      overflow-wrap: break-word;
      font-size: 14px;
    }

    tr:first-child {
      border-left: none;
    }

    td:last-child {
      border-right: none;
    }

    tr:last-child {
      border-right: none;

      td {
        border-bottom: none;
      }
    }

    th {
      background-color: #f2f2f4;
    }

    th:first-child {
      border-top-left-radius: 1rem;
    }

    th:last-child {
      border-top-right-radius: 1rem;
      border-right: none;
    }

    th:first-child,
    td:first-child {
      border-left: none;
    }

    tr {
      border-color: red;

      th:nth-child(2),
      td:nth-child(2) {
        white-space: nowrap;
      }

      th:nth-child(3),
      td:nth-child(3) {
        width: 100%;
      }
    }

    .header-status {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      align-content: flex-start;
      align-items: center;
      justify-content: flex-start;

      div.info {
        display: flex;
      }
    }

    .header-status-icon {
      width: 1.4rem;
      height: 1.4rem;
      margin-right: auto;
      margin-left: auto;
      border-radius: 50%;

      &.incomplete {
        background-color: ${({ theme }) => theme.colors.betterway.red[100]};
      }

      &.processing {
        background-color: ${({ theme }) => theme.colors.betterway.orange[70]};
      }

      &.processed {
        background-color: ${({ theme }) => theme.colors.betterway.green[50]};
      }
    }

    .tab-cell {
      align-content: center;

      &.actions-cell {
        &:last-child {
          padding: 0;
          border-top: none;
        }

        div.actions-buttons {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-right: 0;
          padding-left: 0;
          border-left: none;

          button {
            width: 40%;
            border: none;
            background-color: transparent;
            padding-left: 3px;
            padding-right: 3px;

            &:hover,
            &:focus {
              filter: invert(51%) sepia(93%) saturate(1552%) hue-rotate(156deg) brightness(88%) contrast(102%);
            }

            img {
              max-width: none;
              height: 18px;
            }
          }
        }
      }

      .update-status-text {
        align-self: center;
        font-size: 1.4rem;
      }

      .update-status-button {
        width: 3.4rem;
        height: 3.4rem;

        > button {
          width: 100%;
          height: 100%;
          padding: 0;
          border: none;
          background: ${({ theme }) => theme.colors.betterway.secondary[5]};

          >.picto {
            width: 100%;
            height: 100%;
            color: ${({ theme }) => theme.colors.primaryDark};
          }
        }
      }
    }
  }

  @media (max-width: 1234px) {
    .table {
      tr {
        td:nth-child(2) {
          white-space: normal;
        }
      }
    }
  }
`
