import React from 'react'
import { useTranslation } from 'react-i18next'
import { useExpensesContext } from '../../context/Expenses/ExpensesContext'
import PersonPicker from '../input/PersonPicker/PersonPicker'
import Button from '../input/_ExportDS/Button/Button'
import ExpensesDetails from '../ExpensesDetails/ExpensesDetails'
import ExpensesListStyled from './ExpensesList.styled'
import imageRocket from '../../../assets/images/rocket.svg'
import pictoAdd from '../../../assets/images/add.svg'
import imageQRCodeNDFProduction from '../../../assets/images/octo_qrcode_ndf_prod.png'
import imageQRCodeNDFDemo from '../../../assets/images/octo_qrcode_ndf_demo.png'
import arrow from '../../../../assets/images/arrow.svg'

export default function ExpensesList() {
  const { t } = useTranslation()
  const {
    year,
    person,
    expenses,
    totalAmount,
    setViewToCreate,
    fetchGetPersonExpensesForYear,
    changeExpensesYear,
    expensesYear,
  } = useExpensesContext()

  const QRCodeImage = window.location.hostname === 'octopod.octo.com' || window.location.hostname === 'octopod.octo.tools' ? imageQRCodeNDFProduction : imageQRCodeNDFDemo

  const expensesByMonths = expenses.reduce((acc, expense) => {
    const dayAttribute = expense.attributes.find((attr) => attr.key === 'day')
    const date = new Date(dayAttribute.value)
    const expensesMonth = date.toLocaleString('fr-FR', { month: 'long' })
    if (!acc[expensesMonth]) acc[expensesMonth] = []
    acc[expensesMonth].push(expense)
    return acc
  }, {})

  return (
    <ExpensesListStyled QRCodeImage={QRCodeImage}>
      <div className="expense-header-img" />
      {
        <PersonPicker
          className="person"
          value={person}
          onChange={(value) => fetchGetPersonExpensesForYear(year, value.selectedValue)}
          withPeopleLeft
        />
      }

      {
        person
          ? (
            <>
              <h1>{t('expenses.title')}</h1>

              <div className="year-select">
                <button
                  type="button"
                  onClick={() => changeExpensesYear(-1, person.id)}
                >
                  <img className="arrow left" alt="flèche" src={arrow} />
                </button>
                <div className="year">
                  {expensesYear}
                </div>
                <button
                  type="button"
                  onClick={() => changeExpensesYear(1, person.id)}
                >
                  <img className="arrow right" alt="flèche" src={arrow} />
                </button>
              </div>

              <div className="balance">
                <div className="total">
                  <div className="total-title">Total des notes de frais cette année</div>
                  <div className="total-amount">
                    {totalAmount.toFixed(2)}
                    €
                  </div>
                </div>
                <img src={imageRocket} alt="octo rocket" />
              </div>

              <Button className="add" name="add expense" text={t('expenses.add')} picto={pictoAdd} onClick={() => setViewToCreate()} />

              <div className="expenses">
                {
                  expenses.length > 0
                    ? (
                      Object.entries(expensesByMonths).map(([expensesMonth, expensesOfMonth]) => (
                        <div key={expensesMonth} className="expenses-month">
                          <div className="expenses-title">{expensesMonth}</div>
                          <ExpensesDetails className="expenses-item" expenses={expensesOfMonth} />
                        </div>
                      ))
                    )
                    : ''
                }
              </div>
            </>
          )
          : <div />
      }
    </ExpensesListStyled>
  )
}
