import React from 'react'
import { getClass } from '../../helpers/stringHelper'
import Button from '../input/_ExportDS/Button/Button'
import ExpensesFormReceiptStyled from './ExpensesFormReceipt.styled'
import pictoClose from '../../../assets/images/close2.svg'

export default function ExpensesFormReceipt({ className, receipt, onDestroy }) {
  const { id, url, file } = receipt

  const name = `receipt ${id || url}`
  const isLocalFile = file && (file.type === 'application/pdf' || file.type === 'image/jpeg' || file.type === 'image/png')

  return (
    <ExpensesFormReceiptStyled className={className}>
      {isLocalFile ? <span className="name">{file.name}</span> : <iframe src={url} width="100%" height="600px" title={name} />}
      <Button
        className={getClass('destroy', isLocalFile ? '' : 'floating')}
        name={`${name} destroy`}
        picto={pictoClose}
        onClick={() => onDestroy(receipt)}
      />
    </ExpensesFormReceiptStyled>
  )
}
