import React, { useState, useContext, createContext, useEffect } from 'react'
import { getI18n } from 'react-i18next'
import { parseFloatWithAnySeparator } from '../../helpers/stringHelper'
import { useFetch } from '../../fetchOverviewApi'
import { useJQuery } from '../../hooks/useJQuery/useJQuery'

const PROJECT_SALES_CATEGORIES = ['revenue_additional', 'chargeable', 'nonchargeable']

const ProjectSalesContext = createContext()

export const ProjectSalesContextProvider = ({ children, id }) => {
  const [forms, setForms] = useState({ chargeable: null, nonchargeable: null })
  const [errors, setErrors] = useState([])
  const [project, setProject] = useState(null)
  const [projectRevenue, setProjectRevenue] = useState(null)
  const [projectCosts, setProjectCosts] = useState(null)
  const [projectCCISold, setProjectCCISold] = useState(null)
  const [projectSalesExpenses, setProjectSalesExpenses] = useState([])
  const [projectSnapshot, setProjectSnapshot] = useState(null)
  const [projectActivities, setProjectActivities] = useState([])
  const [profiles, setProfiles] = useState({})
  const [roles, setRoles] = useState({})
  const [serviceAdjustmentBonusAmount, setServiceAdjustmentBonusAmount] = useState(null)
  const [serviceAdjustmentBonusPercent, setServiceAdjustmentBonusPercent] = useState(null)
  const [serviceAdjustmentPenaltyAmount, setServiceAdjustmentPenaltyAmount] = useState(null)
  const [serviceAdjustmentPenaltyPercent, setServiceAdjustmentPenaltyPercent] = useState(null)
  const [contingencyAmount, setContingencyAmount] = useState(null)
  const [contingencyPercent, setContingencyPercent] = useState(null)
  const [serviceEndOfYearDiscountAmount, setServiceEndOfYearDiscountAmount] = useState(null)
  const [serviceEndOfYearDiscountPercent, setServiceEndOfYearDiscountPercent] = useState(null)
  const [subcontractingProjectExpenses, setSubcontractingProjectExpenses] = useState([])
  const [intragroupProjectExpenses, setIntragroupProjectExpenses] = useState([])
  const [miscProjectExpenses, setMiscProjectExpenses] = useState([])
  const [benextProjectExpenses, setBenextProjectExpense] = useState([])
  const [contextSalesLoading, setContextSalesLoading] = useState(false)
  const [skills, setSkills] = useState([])
  const [selectedSkills, setSelectedSkills] = useState([])

  const [turnoverTypeForm, setTurnoverTypeForm] = useState(null)
  const [personRole, setPersonRole] = useState([])
  const [personProfile, setPersonProfile] = useState([])
  const [subcontractor, setSubcontractor] = useState(null)
  const [modalAddStaffing, setModalAddStaffing] = useState(false)
  const [inlineStaffingFormVisible, setInlineStaffingFormVisible] = useState(false)
  const [expenseIdToUpdate, setExpenseIdToUpdate] = useState(0)
  const [isKeyboardNavigation, setIsKeyboardNavigation] = useState(false)
  const { publish, subscribe } = useJQuery()

  const getPersonName = (person) => (person ? `${person['first_name']} ${person['last_name'].toUpperCase()} - ${person['nickname']}` : '')

  const getSubcontractorName = (person) => (person ? `${person['first_name']} ${person['last_name'].toUpperCase()} - ${person['company_name']}` : '')

  const isProjectPresales = () => ['lead', 'proposal_in_progress', 'proposal_sent'].includes(project?.status.current)

  const setFormVisible = (name, args) => setForms({ ...forms, [name]: args || true })

  const setFormHidden = (name) => setForms({ ...forms, [name]: null })

  const presalesTextTranslation = () => (isProjectPresales() ? 'presales' : 'sales')

  const turnoverType = ['from_internal_team', 'intragroup', 'subcontracting']
  const [reloadForm, setReloadForm] = useState(false)
  const activityType = ['Interne OCTO', 'Intragroupe', 'Sous-traitance']
  const [groupDelete, setGroupDelete] = useState([])
  const [tableActivityDeleteMode, setTableActivityDeleteMode] = useState(false)

  const [formStaffing, setFormStaffing] = useState({
    id: null,
    role_id: null,
    title: null,
    expertise: null,
    profile_id: null,
    person_id: null,
    person_search: null,
    nb_days: null,
    average_daily_rate: null,
    project_id: id.toString(),
    total_time_in_days: null,
    skill_ids: [],
  })

  const formStaffingError = {
    role_id: !formStaffing['role_id'] || formStaffing['role_id'].length <= 0,
    title: personRole.find(([, roleId]) => roleId.toString() === formStaffing['role_id'])?.[0] === 'Autre' && (!formStaffing['title'] || formStaffing['title'].length === 0),
    profile_id: !formStaffing['profile_id'] || formStaffing['profile_id'].length <= 0,
    nb_days: !formStaffing['nb_days'] || formStaffing['nb_days'].length <= 0,
    average_daily_rate: !formStaffing['average_daily_rate'] || formStaffing['average_daily_rate'].length <= 0,
  }

  const [formSubcontracting, setFormSubcontracting] = useState({
    turnover_type: null,
    label: null,
    subcontractor_name: null,
    subcontractor_id: null,
    benext_subcontractor_id: null,
    purchase_order: null,
    sale_price: null,
    quantity: null,
    unit_price: null,
    markup: null,
    type: 'SubcontractingProjectExpense',
    project_id: id.toString(),
    id: null,
  })

  const formSubcontractingError = (
    {
      label: !formSubcontracting['label'] || formSubcontracting['label'].length <= 0,
      subcontractor_name: turnoverTypeForm === 'intragroup' ? !formSubcontracting['subcontractor_name'] || formSubcontracting['subcontractor_name'].length <= 0 : false,
      subcontractor_id: turnoverTypeForm !== 'intragroup' ? !formSubcontracting['subcontractor_id'] || formSubcontracting['subcontractor_id'] < 0 : false,
      quantity: !formSubcontracting['quantity'] || formSubcontracting['quantity'] < 0,
      unit_price: !formSubcontracting['unit_price'] || formSubcontracting['unit_price'] < 0,
      markup: !formSubcontracting['markup'] || formSubcontracting['markup'] < 0,
    })

  const addToGroupDelete = async (activityId) => {
    await setGroupDelete(((previous) => {
      if (previous.includes(activityId)) {
        const groupMinusActivity = previous.filter((value) => value !== activityId)
        return ([...groupMinusActivity])
      }
      if (previous.length === 0) {
        return [activityId]
      }
      return [...previous, activityId]
    }
    ))
  }

  const formDefaultValue = async (newTurnoverType, valueInitial) => {
    const skillsValueInitial = valueInitial && valueInitial.skills ? valueInitial.skills.map((skill) => skill.id) : []
    await setFormStaffing((previous) => ({
      ...previous,
      ...{
        id: valueInitial?.id,
        role_id: valueInitial?.roleId,
        title: valueInitial?.title,
        expertise: valueInitial?.expertise,
        profile_id: valueInitial?.profileId,
        person_id: valueInitial?.person?.id,
        person_search: valueInitial?.person ? `${valueInitial.person.first_name} ${valueInitial.person.last_name} - ${valueInitial.person.nickname}` : null,
        nb_days: valueInitial?.daysSold,
        average_daily_rate: valueInitial?.averageDailyRate,
        purchase_order: valueInitial?.purchaseOrder,
        total_time_in_days: valueInitial?.totalTimeInDays,
        skill_ids: skillsValueInitial,
      },
    }))

    await setFormSubcontracting((previous) => ({
      ...previous,
      ...{
        turnover_type: newTurnoverType,
        type: 'SubcontractingProjectExpense',
        label: valueInitial?.label,
        subcontractor_name: valueInitial?.name,
        subcontractor_id: valueInitial?.subcontractorId ? valueInitial.subcontractorId.toString() : null,
        benext_subcontractor_id: valueInitial?.subcontractorId,
        sale_price: valueInitial ? parseFloat(valueInitial?.unitPrice) + parseFloat(valueInitial?.markup) : null,
        quantity: valueInitial?.quantity,
        unit_price: valueInitial?.unitPrice,
        markup: valueInitial?.markup,
        id: valueInitial?.expenseId,
      },
    }))
    setReloadForm(false)
  }

  const updateFormStaffing = async (key, value) => {
    setFormStaffing((previous) => ({ ...previous, [key]: value }))
  }

  const updateFormSubcontracting = async (key, value) => {
    setFormSubcontracting((previous) => ({ ...previous, [key]: value }))
  }

  const fetchGetProject = async () => {
    const response = await useFetch('GET', `/projects/billable/${id}.json`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setProject(response.project)
      setServiceAdjustmentBonusAmount(response.project.service_adjustment_bonus)
      setServiceAdjustmentBonusPercent(response.project.service_adjustment_bonus_percent
        && (parseFloatWithAnySeparator(response.project.service_adjustment_bonus_percent * 100).toFixed(2)))
      setServiceAdjustmentPenaltyAmount(response.project.service_adjustment_penalty)
      setServiceAdjustmentPenaltyPercent(response.project.service_adjustment_penalty_percent
        && (parseFloatWithAnySeparator(response.project.service_adjustment_penalty_percent * 100).toFixed(2)))
      setContingencyAmount(response.project.contingency)
      setContingencyPercent(response.project.contingency_percent && (parseFloatWithAnySeparator(response.project.contingency_percent * 100).toFixed(2)))
      setServiceEndOfYearDiscountAmount(response.project.service_end_of_year_discount)
      setServiceEndOfYearDiscountPercent(
        response.project.service_end_of_year_discount_percent
        && (parseFloatWithAnySeparator(response.project.service_end_of_year_discount_percent * 100).toFixed(2)),
      )
    }
  }

  const fetchSkills = async () => {
    const response = await useFetch('GET', `/skills`)
    setSkills(response.skills)
  }

  const addSkillActivity = async (skillId) => {
    if (formStaffing['skill_ids'].includes(skillId)) return null
    updateFormStaffing('skill_ids', [...formStaffing['skill_ids'], skillId])
    return null
  }

  const removeSkillFromActivity = (skillIdToRemove) => {
    const updateSkillInActivity = formStaffing['skill_ids'].filter((skillId) => skillId !== skillIdToRemove)
    updateFormStaffing('skill_ids', [...updateSkillInActivity])
  }

  const updateSelectedSkills = (skillsToUpdate) => {
    setSelectedSkills(skillsToUpdate)
  }

  const fetchNewActivity = async () => {
    const response = await useFetch('GET', `/activities/new`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setPersonRole(response.role)
      setPersonProfile(response.profile)
    }
  }
  const fetchNewProjectExpense = async () => {
    const response = await useFetch('GET', `/project_expenses/new?project_id=${id}&expense_type=SubcontractingProjectExpense`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setSubcontractor(response.subcontractor)
    }
  }

  const fetchGetProjectSalesExpenses = async () => {
    const response = await useFetch('GET', `/projects/billable/${id}/project_expenses`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setProjectSalesExpenses((response['project_expenses']['misc'] || []).filter(({ category }) => PROJECT_SALES_CATEGORIES.includes(category)))
      publish('/octopod/project/update_service')
    }
  }

  const fetchGetProjectRevenue = async () => {
    const response = await useFetch('GET', `/projects/billable/${id}/project_revenue`)
    if (response.errors) {
      setErrors([...response.errors])
    } else {
      setProjectRevenue(response.project_revenue)
    }
  }

  const fetchAverageDailyRate = async (projectIsAcnPrime, personId, profileId) => {
    if (!projectIsAcnPrime || (!personId && !profileId)) return

    const response = await useFetch('GET', `/activities/acn_prime_average_daily_rate?person_id=${personId}&profile_id=${profileId}`)
    if (response.errors) {
      setErrors([...response.errors])
    } else {
      updateFormStaffing('average_daily_rate', response.average_daily_rate)
    }
  }

  const fetchGetProjectCosts = async () => {
    const response = await useFetch('GET', `/projects/billable/${id}/project_costs`)
    if (response.errors) {
      setErrors([...response.errors])
    } else {
      setProjectCosts(response.project_costs)
    }
  }

  const fetchGetProjectCCISold = async () => {
    const response = await useFetch('GET', `/projects/billable/${id}/cci_sold`)
    if (response.errors) {
      setErrors([...response.errors])
    } else {
      setProjectCCISold(response.cci_sold)
    }
  }

  const fetchGetProjectSnapshot = async () => {
    const response = await useFetch('GET', `/projects/billable/${id}/snapshot?locale=${getI18n().language}`)
    if (response.errors) {
      setErrors([...response.errors])
    } else {
      setProjectSnapshot(response.snapshot)
    }
  }

  const fetchGetActivities = async () => {
    const response = await useFetch('GET', `/projects/billable/${id}/activities`)
    if (response.errors) {
      setErrors([...response.errors])
    } else {
      setProjectActivities(response.activities)
      setProfiles(response.profiles)
      setRoles(response.roles)
    }
  }

  const updateProjectSalesHeaderValues = () => {
    fetchGetProjectRevenue()
    fetchGetProject()
    fetchGetProjectCCISold()
    fetchGetProjectCosts()
    publish('/octopod/project/update_project')
  }

  const fetchPutAssignPerson = async (addPersonPresales) => {
    const response = await useFetch('PUT', `/activities/assign_person`, addPersonPresales)
    if (response.errors) {
      setErrors([...response.errors])
    } else {
      updateProjectSalesHeaderValues()
      fetchGetActivities()
      publish('/octopod/project/turnover_breakdown')
    }
  }
  const fetchPutUnAssignPerson = async (destroyPersonPresales) => {
    const response = await useFetch('PUT', `/activities/unassign_person`, destroyPersonPresales)
    if (response.errors) {
      setErrors([...response.errors])
    } else {
      updateProjectSalesHeaderValues()
      fetchGetActivities()
      publish('/octopod/project/turnover_breakdown')
    }
  }

  const fetchPutActivities = async (activities) => {
    const response = await useFetch('PUT', `/projects/billable/${id}/activities`, activities)
    if (response.errors) {
      setErrors([...response.errors])
    } else {
      setProjectActivities(response.activities)
      updateProjectSalesHeaderValues()
      fetchGetActivities()
      publish('/octopod/project/turnover_breakdown')
    }
  }

  const fetchGetProjectExpenses = async () => {
    const response = await useFetch('GET', `/projects/billable/${id}/project_expenses`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setSubcontractingProjectExpenses(response['project_expenses'].subcontracting)
      setIntragroupProjectExpenses(response['project_expenses'].intragroup)
      setMiscProjectExpenses((response['project_expenses'].misc || []).filter(({ category }) => !PROJECT_SALES_CATEGORIES.includes(category)))
      setBenextProjectExpense(response['project_expenses'].benext)
    }
  }

  const fetchUpdateFields = async (value, activityId, field) => {
    const response = await useFetch('PUT', `/activities/update_fields`, { value, id: activityId, field, decimal_separator: ',' })
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      fetchGetProject()
      publish('/octopod/project/turnover_breakdown')
    }
  }

  const fetchGetKPIs = async () => {
    await setContextSalesLoading(true)
    await fetchGetProject()
    await fetchGetProjectSalesExpenses()
    await fetchGetProjectRevenue()
    await fetchGetProjectCosts()
    await fetchGetProjectCCISold()
    await fetchGetProjectSnapshot()
    await fetchGetActivities()
    await fetchGetProjectExpenses()
    await fetchNewActivity()
    await fetchNewProjectExpense()
    await setContextSalesLoading(false)
  }

  const setProjectExpenseByType = (newProjectExpense) => {
    if (newProjectExpense?.turnover_type === 'intragroup') return setIntragroupProjectExpenses((prevProjectExpenses) => [...prevProjectExpenses, newProjectExpense])
    if (newProjectExpense?.turnover_type === 'subcontracting') return setSubcontractingProjectExpenses((prevProjectExpenses) => [...prevProjectExpenses, newProjectExpense])
    if (newProjectExpense?.turnover_type === 'misc') return setMiscProjectExpenses((prevProjectExpenses) => [...prevProjectExpenses, newProjectExpense])
    if (newProjectExpense?.turnover_type === 'benext') return setMiscProjectExpenses((prevProjectExpenses) => [...prevProjectExpenses, newProjectExpense])
    return null
  }

  const fetchCreateProjectExpense = async () => {
    const response = await useFetch('POST', `/project_expenses/create`, { project_expense: formSubcontracting })
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setProjectExpenseByType(response.project_expense)
      setTurnoverTypeForm(null)
      formDefaultValue(null)
      updateProjectSalesHeaderValues()
      fetchGetProjectExpenses()
      fetchGetKPIs()
      publish('/octopod/project/turnover_breakdown')
    }
  }

  const fetchCreateActivity = async () => {
    const response = await useFetch('POST', `/activities`, { activity: formStaffing })
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setProjectActivities((prevActivities) => [...prevActivities, response.activity])
      setTurnoverTypeForm(null)
      formDefaultValue(null)
      updateProjectSalesHeaderValues()
      fetchGetActivities()
      fetchGetProjectExpenses()
      fetchGetKPIs()
      publish('/octopod/project/turnover_breakdown')
    }
  }

  const fetchDeleteActivities = async () => {
    const response = await useFetch('DELETE', `/activities/bulk_delete/`, { ids: groupDelete })
    if (response.errors) {
      setErrors([...errors, ...response.errors])
      setGroupDelete([])
    } else {
      setGroupDelete([])
      publish('/octopod/project/turnover_breakdown')
      await fetchGetKPIs()
    }
  }

  const fetchDeleteProjectExpense = async () => {
    const response = await useFetch('DELETE', `/project_expenses/bulk_delete/`, { ids: groupDelete })
    if (response.errors) {
      setErrors([...errors, ...response.errors])
      setGroupDelete([])
    } else {
      setGroupDelete([])
      publish('/octopod/project/turnover_breakdown')
      await fetchGetKPIs()
    }
  }

  const fetchUpdateService = async (service) => {
    const response = await useFetch('PATCH', `/projects/billable/${id}/update_service`, { service })
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setProject({ ...project, service: response.project.service })
      publish('/octopod/project/update_service')
      await fetchGetKPIs()
    }
  }

  const fetchUpdateServiceAdjustmentBonus = async (amount, percent) => {
    const response = await useFetch('PATCH', `/projects/billable/${id}/update_service_adjustment_bonus`, {
      service_adjustment_bonus: amount,
      service_adjustment_bonus_percent: percent,
    })
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setProject({ ...project, service_adjustment_bonus: response.service_adjustment_bonus, service_adjustment_bonus_percent: response.service_adjustment_bonus_percent })
      setServiceAdjustmentBonusAmount(response.service_adjustment_bonus)
      setServiceAdjustmentBonusPercent(response.service_adjustment_bonus_percent && (parseFloatWithAnySeparator((response.service_adjustment_bonus_percent * 100).toFixed(2))))

      await fetchGetKPIs()
    }
  }

  const fetchUpdateServiceAdjustmentPenalty = async (amount, percent) => {
    const response = await useFetch('PATCH', `/projects/billable/${id}/update_service_adjustment_penalty`, {
      service_adjustment_penalty: amount,
      service_adjustment_penalty_percent: percent,
    })
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setProject({ ...project, service_adjustment_penalty: response.service_adjustment_penalty, service_adjustment_penalty_percent: response.service_adjustment_penalty_percent })
      setServiceAdjustmentPenaltyAmount(response.service_adjustment_penalty)
      setServiceAdjustmentPenaltyPercent(response.service_adjustment_penalty_percent
        && (parseFloatWithAnySeparator((response.service_adjustment_penalty_percent * 100).toFixed(2))))

      await fetchGetKPIs()
    }
  }

  const fetchCreateProjectSalesExpense = async (expense) => {
    const response = await useFetch('POST', `/project_expenses/create`, {
      'project_expense': {
        ...expense,
        'label': expense.label || `${expense['category']} ${expense['turnover_type']}`,
        'project_id': project.id,
      },
    })

    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setFormHidden(expense.category)
      publish('/octopod/project/turnover_breakdown')

      await fetchGetKPIs()
    }
  }

  const fetchUpdateProjectSalesExpense = async (expense) => {
    const response = await useFetch('PUT', `/project_expenses/update`, {
      'project_expense': {
        ...expense,
        'project_id': project.id,
      },
    })

    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setFormHidden(expense.category)
      publish('/octopod/project/turnover_breakdown')

      await fetchGetKPIs()
    }
  }

  const fetchUpdateContingency = async (percent) => {
    const response = await useFetch('PATCH', `/projects/billable/${id}/update_contingency`, { contingency_percent: percent })
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setProject({ ...project, contingency: response.contingency, contingency_percent: response.contingency_percent })
      setContingencyAmount(response.contingency)
      setContingencyPercent(response.contingency_percent && (parseFloatWithAnySeparator((response.contingency_percent * 100).toFixed(2))))

      await fetchGetKPIs()
    }
  }

  const fetchUpdateServiceEndOfYearDiscount = async (amount, percent) => {
    const response = await useFetch('PATCH',
      `/projects/billable/${id}/update_service_end_of_year_discount`,
      { service_end_of_year_discount: amount, service_end_of_year_discount_percent: percent })

    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      setProject({
        ...project,
        service_end_of_year_discount: response.service_end_of_year_discount,
        service_end_of_year_discount_percent: response.service_end_of_year_discount_percent,
      })
      setServiceEndOfYearDiscountAmount(response.service_end_of_year_discount)
      setServiceEndOfYearDiscountPercent(
        response.service_end_of_year_discount_percent
        && (parseFloatWithAnySeparator((response.service_end_of_year_discount_percent * 100).toFixed(2))),
      )

      await fetchGetKPIs()
    }
  }

  const fetchDestroyProjectSalesExpense = async (expense) => {
    const response = await useFetch('DELETE', `/project_expenses/delete`, { 'project_id': id, 'project_expense_id': expense.id })
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      await fetchGetKPIs()
    }
  }

  const fetchGetProjectSubcontractors = async () => {
    const response = await useFetch('GET', `/projects/billable/${id}/active_project_subcontractors`)
    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      return response['project_subcontractors']
    }
    return {}
  }

  const fetchGetSubcontractors = async () => {
    const response = await useFetch('GET', `/project_expenses/active_subcontractors`)

    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      return response['active_subcontractors']
    }
    return {}
  }

  const fetchPatchProjectPricingExport = async (numberOfMonths) => {
    const response = await useFetch(
      'PATCH',
      `/projects/billable/${id}/pricing_report`,
      { billable_project: { payment_delay: numberOfMonths } },
      false,
      false,
    )

    response.blob().then((blob) => {
      const contentDisposition = response.headers.get('content-disposition')
      const filename = contentDisposition.split('filename*=UTF-8\'\'')[1]
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = filename
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    })
    return {}
  }

  const fetchPutProjectExpenses = async (projectExpense) => {
    const response = await useFetch('PUT', `/project_expenses/update`, { project_expense: projectExpense })

    if (response.errors) {
      setErrors([...errors, ...response.errors])
    } else {
      await updateProjectSalesHeaderValues()
      await fetchGetProjectExpenses()
      await fetchGetKPIs()
      publish('/octopod/project/turnover_breakdown')
    }
  }

  useEffect(() => {
    subscribe('/octopod/tabchange', () => fetchGetKPIs(), 0)
    subscribe('/octopod/project/update_kpis', () => fetchGetKPIs(), 0)
    subscribe('/octopod/project_amount/updated', () => fetchGetKPIs(), 0)
    subscribe('/octopod/project_expenses_quantities/reload_calculations', () => fetchGetKPIs(), 0)

    fetchGetKPIs()
  }, [])

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Tab' || e.key === 'Enter') {
        setIsKeyboardNavigation(true)
      }
    }

    const handleMouseDown = () => {
      setIsKeyboardNavigation(false)
    }

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('mousedown', handleMouseDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('mousedown', handleMouseDown)
    }
  }, [turnoverTypeForm])

  useEffect(() => {
    fetchSkills()
  }, [])

  return (
    <ProjectSalesContext.Provider value={{
      activityType,
      skills,
      addSkillActivity,
      selectedSkills,
      updateSelectedSkills,
      removeSkillFromActivity,
      turnoverTypeForm,
      forms,
      errors,
      formStaffing,
      formSubcontracting,
      personRole,
      personProfile,
      formSubcontractingError,
      formStaffingError,
      setReloadForm,
      reloadForm,
      subcontractor,
      getPersonName,
      getSubcontractorName,
      modalAddStaffing,
      setModalAddStaffing,
      expenseIdToUpdate,
      setExpenseIdToUpdate,
      inlineStaffingFormVisible,
      setInlineStaffingFormVisible,
      project,
      projectRevenue,
      projectCosts,
      projectSalesExpenses,
      projectCCISold,
      projectSnapshot,
      projectActivities,
      profiles,
      roles,
      subcontractingProjectExpenses,
      intragroupProjectExpenses,
      miscProjectExpenses,
      benextProjectExpenses,
      isProjectPresales,
      presalesTextTranslation,
      setFormVisible,
      setFormHidden,
      setTurnoverTypeForm,
      addToGroupDelete,
      tableActivityDeleteMode,
      setTableActivityDeleteMode,
      serviceAdjustmentBonusAmount,
      serviceAdjustmentBonusPercent,
      serviceAdjustmentPenaltyAmount,
      serviceAdjustmentPenaltyPercent,
      contingencyAmount,
      contingencyPercent,
      serviceEndOfYearDiscountAmount,
      serviceEndOfYearDiscountPercent,
      contextSalesLoading,
      turnoverType,
      formDefaultValue,
      updateFormStaffing,
      updateFormSubcontracting,
      fetchGetProject,
      fetchCreateActivity,
      fetchAverageDailyRate,
      fetchCreateProjectExpense,
      fetchUpdateService,
      fetchDeleteActivities,
      fetchPutAssignPerson,
      fetchPutUnAssignPerson,
      fetchUpdateServiceAdjustmentBonus,
      fetchUpdateServiceAdjustmentPenalty,
      fetchUpdateContingency,
      fetchGetProjectSalesExpenses,
      fetchCreateProjectSalesExpense,
      fetchUpdateProjectSalesExpense,
      fetchUpdateServiceEndOfYearDiscount,
      fetchDestroyProjectSalesExpense,
      fetchGetProjectRevenue,
      fetchGetProjectCosts,
      fetchGetProjectCCISold,
      fetchGetProjectSnapshot,
      fetchGetActivities,
      fetchPutActivities,
      fetchGetKPIs,
      fetchDeleteProjectExpense,
      fetchGetProjectSubcontractors,
      fetchPatchProjectPricingExport,
      fetchPutProjectExpenses,
      fetchGetSubcontractors,
      fetchGetProjectExpenses,
      setServiceAdjustmentBonusAmount,
      setServiceAdjustmentBonusPercent,
      setServiceAdjustmentPenaltyAmount,
      setServiceAdjustmentPenaltyPercent,
      fetchUpdateFields,
      setContingencyAmount,
      setContingencyPercent,
      setServiceEndOfYearDiscountAmount,
      setServiceEndOfYearDiscountPercent,
      groupDelete,
      setGroupDelete,
      isKeyboardNavigation,
      setIsKeyboardNavigation,
    }}
    >
      {children}
    </ProjectSalesContext.Provider>
  )
}

export const useProjectSalesContext = () => useContext(ProjectSalesContext)
