import styled from 'styled-components'
import imageOcto from '../../../assets/images/octo.svg'

export const ProjectActivitiesTimeSpentTableStyled = styled.div`
  &.octo {
    >.view .wrapper {
      > table {
        border-collapse: collapse;

        > tbody tr.section-sub.item {
          display: ${(props) => (props.presalesToggle ? 'none' : 'table-row')};
        }

        > tr {
          .title {
            font-weight: 600;
            font-size: 1.6rem;
          }
        }

        thead > tr > th {
          position: sticky;
          top: 0;
          z-index: 4;
        }

        tbody > tr > td:not(:nth-child(1)) {
          text-align: right;
        }

        thead > tr > th:first-child,
        thead > tr > th:nth-of-type(5) {
          z-index: 5;
          background-color: ${({ theme }) => theme.colors.betterway.primary[20]};
        }

        tbody > tr > td:first-child,
        tfoot > tr > td:first-child,
        thead > tr > th:first-child {
          position: sticky;
          left: 0;
          width: ${(props) => (props.activitiesDeleteMode ? `15rem` : `29rem`)};
          min-width: ${(props) => (props.activitiesDeleteMode ? `15rem` : `29rem`)};
        }

        tbody > tr > td:nth-child(2),
        tfoot > tr > td:nth-child(2),
        thead > tr > th:nth-child(2) {
          width: ${(props) => (props.activitiesDeleteMode ? `29rem` : `13rem`)};
          min-width: ${(props) => (props.activitiesDeleteMode ? `29rem` : `13rem`)};
          text-align: ${(props) => (props.activitiesDeleteMode ? 'left' : 'right')};

          .control {
            min-width: ${(props) => (props.activitiesDeleteMode ? `29rem` : `13rem`)};
          }
        }

        tbody > tr > td:nth-child(3),
        tfoot > tr > td:nth-child(3),
        thead > tr > th:nth-child(3) {
          .control {
            min-width: 13rem;
          }
        }

        tbody > tr > td:first-child,
        tbody > tr > td:nth-child(7) {
          z-index: 3;
        }

        > tbody > tr > td:nth-child(5),
        > tfoot > tr > td:nth-child(5) {
          position: ${(props) => (props.activitiesDeleteMode ? `static` : `sticky`)};
          left: ${(props) => (props.activitiesDeleteMode ? `0` : `28rem`)};
          ${(props) => !props.activitiesDeleteMode && props.compactedVue && (`
            left: 28.5rem;
          `)}
        }

        > thead > tr > th:nth-of-type(5) {
          position: sticky;
          left: ${(props) => (props.activitiesDeleteMode ? `0` : `28rem`)};
          z-index: ${(props) => (props.activitiesDeleteMode ? `3` : `5`)};
          ${(props) => !props.activitiesDeleteMode && props.compactedVue && (`
            left: 28.5rem;
          `)}
        }

        > tbody > tr > td:nth-child(6),
        > tfoot > tr > td:nth-child(6) {
          position: ${(props) => (props.activitiesDeleteMode ? `sticky` : `static`)};
          left: ${(props) => (props.activitiesDeleteMode ? `14.5rem` : `0`)};
          z-index: ${(props) => (props.activitiesDeleteMode ? `5` : `0`)};
        }

        > tbody > tr > td:not(:last-child),
        > tfoot > tr > td:not(:last-child),
        > thead > tr > th:not(:last-child) {
          border-right: 0.0625em solid ${({ theme }) => theme.colors.betterway.primary[50]};
        }

        > thead > tr > th:nth-of-type(6) {
          position: sticky;
          left: ${(props) => (props.activitiesDeleteMode ? `14.5rem` : `0`)};
          z-index: ${(props) => (props.activitiesDeleteMode ? `6` : `0`)};
        }

        > tbody > tr > td {
          background-color: inherit;
          ${(props) => props.compactedVue && (`
            padding: 0.64rem;
          `)}
        }

        > tfoot > tr > td {
          background-color: ${({ theme }) => theme.colors.betterway.primary[20]};
        }

        > thead > tr > th:nth-of-type(2) {
          border-left: none;
        }

        tfoot > tr > td:not(:nth-child(1)) {
          text-align: right;
        }

        > tfoot {
          font-weight: 600;
        }

        > tr.line-with-border {
          display: ${(props) => (props.hasPresalesActivities ? 'table-row' : 'none')};
        }

        .unit {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;

          &.price {
            white-space: pre;

            .control::after {
              content: ' €';
              display: flex;
              flex-flow: row;
              align-items: center;
              justify-content: end;
              margin-bottom: 0.2rem;
              white-space: pre;
            }
          }
        }

        .days-sold {
          >.control {
            min-width: 12rem;
          }
        }

        .daily-rate {
          >.control {
            min-width: 14rem;
          }
        }

        tbody > tr > td.octo.hard.start-date > div.cell > div.octo.hard > div.control {
          width: 20rem;
          min-width: 20rem;
        }

        .person-details {
          span {
            font-weight: 600;
            font-size: 1.6rem;

            &.inline-content {
              display: inline-flex;
            }

            &.spaced {
              margin-right: 0.25rem;
              margin-left: 0.25rem;
            }
          }

          >p {
            white-space: break-spaces;
          }

          p {
            display: flex;
            overflow: hidden;
            width: 25rem;

            img {
              margin-right: 0.5rem;
            }
          }

          .list-skill {
            display: flex;
            gap: 0.3em;
            font-size: 0.8em;

            .more-skills {
              font-weight: bold;
              cursor: pointer;
            }
          }

          a {
            display: flex;
            align-items: center;
            font-weight: 400;
            font-size: 1.4rem;
            text-decoration: underline;
            cursor: pointer;

            img {
              width: 2.1rem;
              height: 2.1rem;
              margin-left: 0.4rem;
            }
          }
        }

        .subtotal {
          font-weight: 600;
        }

        .subtotal-footer {
          font-weight: 600;
        }

        .presales-header {
          button {
            display: ${(props) => (props.hasPresalesActivities ? 'flex' : 'none')};
            gap: 0.4rem;
            align-items: center;
            margin-top: 0.6rem;
            font-size: 1.4rem;
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .title {
          font-weight: 600;
          font-size: 1.6rem;
        }

        .cell > div.input-radio-destroy {
          display: flex;
          gap: 1rem;
          align-items: center;
          width: auto;

          img {
            width: 2.4rem;
            height: 2.4rem;
            margin: 0;
          }

          .button-delete-activity {
            width: auto;

            &.disabled {
              button {
                .checkbox {
                  background-color: ${({ theme }) => theme.colors.betterway.primary[70]} !important;

                  .checkbox-icon {
                    background-color: ${({ theme }) => theme.colors.betterway.primary[70]} !important;
                  }
                }
              }
            }
          }

          .delete-mode-hide {
            width: 0%;
          }

          .control {
            margin: 0;
            padding: 0;
          }
        }
      }

      .progress-bar .container .bar {
        .percentage::before {
          content: '';
          background-image: url(${imageOcto});
        }
      }
    }

    .view {
      .control {
        display: flex;
        gap: 0.8rem;
        width: auto;
      }

      div.control.button-container {
        margin-top: 1rem;
      }
    }

    .wrapper {
      position: relative;
      overflow: auto;
      max-height: 80rem;
      border-radius: 2rem;
      white-space: nowrap;
    }

    .compact {
      &.octo.hard.default {
        > button {
          gap: 0.4rem;
          width: fit-content;
          padding: 0;
          border: none;
          background: none;
          color: ${({ theme }) => theme.colors.betterway.primary[100]};
          font-weight: normal;
          font-size: 1.2rem;
          text-decoration: underline;

          &:hover {
            background: none;
            color: ${({ theme }) => theme.colors.betterway.primary[100]};
            font-weight: bold;
          }

          &:focus,
          &:focus-visible {
            border: none;
            color: ${({ theme }) => theme.colors.betterway.primary[100]};
          }
        }
      }
    }

    .copy-succeed {
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;
      padding: 1.6rem;
      border: solid 0.2rem ${({ theme }) => theme.colors.betterway.green[100]};
      border-radius: 0.3rem;
      background-color: ${({ theme }) => theme.colors.betterway.green[5]};
    }
  }
`
