import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: stretch;
  box-sizing: border-box;
  width: 100%;
  padding: 2rem 20%;

  >.header {
    position: relative;
    display: flex;
    flex: 0 0 5.6rem;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 1.6rem;
    padding: 0 2.8rem;

    > button.previous {
      position: absolute;
      top: 0.4rem;
      left: -1.2rem;
      box-sizing: border-box;
      width: 4.8rem;
      height: 4.8rem;
      padding: 0;
      border: none;
      background-color: ${({ theme }) => theme.colors.betterway.transparent};
    }

    >.title {
      font-weight: 400;
      font-size: 1.7rem;
      text-align: center;
    }
  }

  >.description {
    padding-bottom: 1.6rem;
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.betterway.primary[20]};
    font-weight: 400;
    font-size: 1.6rem;

    >.warning {
      font-weight: 700;
      font-size: 1.6rem;
    }
  }

  .filled {
    > div {
      > button {
        border-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
        color: ${({ theme }) => theme.colors.betterway.primary[100]};
      }
    }

    >.area {
      border-color: ${({ theme }) => theme.colors.betterway.secondary[100]};

      > textarea {
        color: ${({ theme }) => theme.colors.betterway.primary[100]};
      }
    }

    > input {
      border-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
      color: ${({ theme }) => theme.colors.betterway.primary[100]};
    }
  }

  >.form {
    width: 100%;
    margin: 0;

    >.control {
      margin-top: 2.2rem;

      &.hidden {
        display: none;
        visibility: hidden;
      }

      >.receipt-title {
        margin-bottom: 0.3rem;
        color: ${({ theme }) => theme.colors.betterway.primary[70]};
        font-weight: 400;
        font-style: normal;
        font-size: 1.7rem;
      }

      >.receipt {
        margin-bottom: 1.6rem;
      }

      >.receipt-uploader {
        margin-top: 1.6rem;

        button {
          width: 100%;
          height: auto;
          padding: 0;
          border: none;
          background-color: ${({ theme }) => theme.colors.betterway.transparent};
          color: ${({ theme }) => theme.colors.betterway.secondary[100]};
          font-size: 1.6rem;
        }
      }
    }

    >.form-validation {
      box-sizing: border-box;
      width: 100%;
      margin: 0;
      margin: 2.2rem 0 0;
      padding: 0;

      >.form-validation-trigger {
        > button {
          justify-content: center;
          border-color: ${({ theme }) => theme.colors.betterway.primary[100]};
          background-color: ${({ theme }) => theme.colors.betterway.primary[100]};
          color: ${({ theme }) => theme.colors.betterway.white};

          &:hover {
            border-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
            background-color: ${({ theme }) => theme.colors.betterway.secondary[100]};
          }

          &:disabled {
            border-color: ${({ theme }) => theme.colors.betterway.primary[50]};
            background-color: ${({ theme }) => theme.colors.betterway.primary[50]};
            color: ${({ theme }) => theme.colors.betterway.white};
          }
        }
      }
    }
  }

  @media (max-width: 1234px) {
    padding: 2rem;
  }
`
