import styled from 'styled-components'

export default styled.thead`
  color: ${({ theme }) => theme.colors.white};

  th {
    position: sticky;
    top: -2rem;
  }

  tr {
    &:first-child {
      background-color: ${({ theme }) => theme.colors.primaryBlue};

      /* stylelint-disable-next-line no-descending-specificity */
      th {
        box-sizing: border-box;
        width: 2.8rem;
        height: 7rem;
        padding: 0.3rem;
        background: ${({ theme }) => theme.colors.primaryBlue};

        &:first-child {
          z-index: 99;
          padding-left: 1rem;
          border-radius: 5px 0 0;
          text-align: left;
        }

        &:nth-last-child(-n + 10) {
          .popin {
            right: 0;
            left: initial;
          }
        }

        &:last-child {
          width: 6.8rem;
          border-radius: 0 5px 0 0;
        }

        &.current-day {
          background-color: ${({ theme }) => theme.colors.secondary} !important;
        }

        &.current-day::before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          height: 3.6rem;
          margin: -50% 0 0;
          border-radius: 50% 50% 0 0;
          background-color: ${({ theme }) => theme.colors.secondary};
        }
      }
    }

    &:last-child {
      background-color: ${({ theme }) => theme.colors.lightBlue};

      th {
        top: 5rem;
        z-index: 99;
        padding: 6px 0;
        background: ${({ theme }) => theme.colors.lightBlue};

        &.current-day {
          background: ${({ theme }) => theme.colors.secondary};
        }

        &:first-child {
          z-index: 99;
          height: 100%;
          padding-right: 1.3rem;
          padding-left: 1.3rem;
          background: ${({ theme }) => theme.colors.primaryBlue};
          font-size: 1.4rem;
        }

        &:last-child {
          min-width: 8rem;
          max-width: 8rem;

          span {
            padding: 4px 10px;
            border: 1px solid ${({ theme }) => theme.colors.secondaryBlue};
            border-radius: 3px;
            white-space: nowrap;
          }
        }

        div {
          display: flex;
        }

        button {
          height: 2rem;
          margin-left: 3rem;
          padding: 0 2rem;
          border: none;
          border-radius: 4.8rem;
          background: ${({ theme }) => theme.colors.white};
          transition: cubic-bezier(0.35, 0, 0.65, 1) 0.2s;

          :focus {
            outline: none;
          }

          :hover {
            background: ${({ theme }) => theme.colors.primaryGreyLight};
          }
        }

        &:nth-child(2) {
          padding-right: 1.3rem;
          background: ${({ theme }) => theme.colors.primaryBlue};
        }
      }
    }
  }
`
