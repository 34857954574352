import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button, InputNumber, InputRadio, InputSelect, InputSearch, InputText, Section, InputDate, Table,
  Tooltip,
} from '@produits-internes-oss/design-system-components'
import { filter } from '../../helpers/objectHelper'
import { useDebouncer } from '../../hooks/useDebouncer/useDebouncer'
import { useSearch } from '../../hooks/useSearch/useSearch'
import { useProjectSalesContext } from '../../context/ProjectSale/ProjectSalesContext'
import { ProjectSalesActivitiesStyled } from './ProjectSalesActivities.styled'
import { getNumberWithUnit, getClass, getYearMonthDayFromDateString } from '../../helpers/stringHelper'
import { getRoundedToDecimal } from '../../helpers/numberHelper'
import { monthList } from '../../helpers/datePickerHelper'
import pictoYes from '../../../assets/images/tagYes.svg'
import pictoNo from '../../../assets/images/tagNo.svg'
import pictoCopy from '../../../assets/images/copy.svg'
import pictoNavigateCross from '../../../assets/images/iconsNavigateCross.svg'
import pictoDestroy from '../../../assets/images/destroy.svg'
import { ProjectStaffingFormHandler } from '../ProjectStaffingFormHandler/ProjectStaffingFormHandler'
import { ProjectInlineStaffingForm } from '../ProjectInlineStaffingForm/ProjectInlineStaffingForm'
import { useJQuery } from '../../hooks/useJQuery/useJQuery'
import { ProjectSelectSkillsActivity } from '../ProjectSelectSkillsActivity/ProjectSelectSkillsActivity'

export const modifyFirstColumn = (columnsDefault, deleteColumn, activitiesDeleteMode) => {
  if (activitiesDeleteMode) {
    columnsDefault.splice(0, 0, deleteColumn)
  }
  return columnsDefault
}

export const totalMonthsExpenseQuantities = (expenseQuantities) => {
  let totalExpenseQuantities = 0
  expenseQuantities.map((expense) => {
    totalExpenseQuantities += expense.quantity
    return null
  })

  return totalExpenseQuantities
}

export const ProjectSalesActivitiesTable = ({ isEditable, setOpenModal }) => {
  const { t } = useTranslation()
  const { debounce } = useDebouncer()
  const { fetchSearch } = useSearch()
  const { publish } = useJQuery()
  const {
    projectActivities,
    projectSnapshot,
    project,
    getPersonName,
    fetchPutActivities,
    profiles,
    roles,
    addToGroupDelete,
    fetchPutUnAssignPerson,
    fetchDeleteActivities,
    tableActivityDeleteMode,
    setTableActivityDeleteMode,
    isProjectPresales,
    inlineStaffingFormVisible,
    setInlineStaffingFormVisible,
    turnoverTypeForm,
    setExpenseIdToUpdate,
  } = useProjectSalesContext()

  const [activities, setActivities] = useState(null)
  const [copyToClipboardSuccess, setCopyToClipboardSuccess] = useState(false)
  const table = useRef()
  const [activitiesDeleteMode, setActivitiesDeleteMode] = useState(false)

  useEffect(() => {
    const activitiesSource = projectSnapshot?.activities || projectActivities
    const activitiesWithoutPresales = activitiesSource.filter((activity) => !activity.presales)
    setActivities(Object.fromEntries(activitiesWithoutPresales.map((activity) => [activity.id, {
      ...activity,
      'average_daily_rate': getRoundedToDecimal(parseFloat(activity['average_daily_rate']), 2),
      'nb_days': getRoundedToDecimal(parseFloat(activity['nb_days']), 2),
      'skills': isEditable ? activity.skills : activity.skill_ids,
      'total': getRoundedToDecimal(parseFloat(activity['average_daily_rate']) * parseFloat(activity['nb_days']), 2),
    }])))
  }, [projectActivities, projectSnapshot])

  const copyToClipboard = () => {
    if (table.current.childNodes[0]) {
      const nodes = Array.from(table.current.childNodes[0].childNodes)
        .flatMap((child) => Array.from(child.childNodes)).flatMap((child) => Array.from(child.childNodes))
        .map((line) => Array.from(line.childNodes))
      const header = nodes[0].map((cell) => cell.textContent)
      const footer = nodes[nodes.length - 1].map((cell) => cell.textContent)

      let lines = []
      if (nodes.length > 2) {
        lines = nodes.slice(1, nodes.length - 1)
          .map((line) => line.map((cell) => {
            const input = cell.getElementsByTagName('input')[0]
            const checkbox = cell.getElementsByClassName('checkbox')[0]
            const button = cell.getElementsByTagName('button')[0]
            const imgNotInIDF = cell.getElementsByClassName('is-not-in-idf')[0]
            const imgInIDF = cell.getElementsByClassName('is-in-idf')[0]
            return (
              (checkbox && ((cell.getElementsByClassName('item checked')[0] && t('yes')) || t('no')))
              || (input && input.value)
              || (button && button.textContent)
              || (imgNotInIDF && t('no'))
              || (imgInIDF && t('yes'))
              || (cell.textContent)
            )
          }).join('\t'))
      }

      navigator.clipboard.writeText([header.join('\t'), ...lines, footer.join('\t')].join('\n'))
    }
    setCopyToClipboardSuccess(true)
    setTimeout(() => {
      setCopyToClipboardSuccess(false)
    }, 5000)
  }

  const updateActivity = (id, key, value) => {
    const activity = {}
    activity['id'] = parseInt(id, 10)
    activity[`${key}`] = value
    const activityToUpdate = filter(activity, (k) => !['people', 'profile', 'role', 'total'].includes(k))
    if (key === 'people') {
      activityToUpdate['person_ids'] = activity.people.map((person) => person.id)
    }
    debounce(() => fetchPutActivities([activityToUpdate]), 1000)
  }

  const activityPerson = (name, item) => {
    if (!isEditable) {
      return (
        getPersonName(item['people']?.[0]) || '-'
      )
    }

    if (item['people'].length && item['people'][0]?.['id'] && item['people'][0]?.['last_name']) {
      return (
        <div className="activity-person">
          <a href={`/timesheet/${item['people'][0]['nickname']}`} target="_blank" rel="noreferrer">{getPersonName(item['people']?.[0])}</a>

          <Tooltip
            className="destroy-tooltip"
            position="right"
            skin="light"
            trigger={
              ({ open, close }) => (
                <div
                  onMouseEnter={() => item.total_time_in_days !== 0 && open()}
                  onMouseLeave={() => item.total_time_in_days !== 0 && close()}
                >
                  <Button
                    className="destroy"
                    onClick={() => fetchPutUnAssignPerson({
                      person_id: item['people'][0]['id'],
                      activity_id: item['id'],
                    })}
                    disabled={item.total_time_in_days !== 0}
                    icon={{ url: pictoDestroy }}
                  />
                </div>
              )
            }
            content={
              () => (
                <div>
                  <p>Cette personne a déjà pointé sur cette ligne, vous ne pouvez pas la désaffecter</p>
                </div>
              )
            }
          />
        </div>
      )
    }
    return (
      <div>
        <InputSearch
          className="role"
          skin="light"
          name="person search"
          valueInitial={{ key: item['people'][0]?.id, search: item['people'][0]?.search || getPersonName(item['people'][0]) }}
          onSearch={(search) => debounce(() => search.length >= 3 && fetchSearch(search, 'Person', null, getPersonName), 500)}
          onChange={({ key, search }) => (!search || key) && updateActivity(name, 'people', key ? [{ id: key, search }] : [])}
        />
      </div>
    )
  }

  const deleteColumn = {
    name: 'delete_activity',
    title: `${activitiesDeleteMode ? t('project.activities.delete') : ''}`,
    onRender: ({ item }) => (activitiesDeleteMode
      ? (
        <div className="input-radio-destroy">
          <InputRadio
            multiple="false"
            className="form-control radio radio-inline button-delete-activity"
            skin="light"
            name="delete_activity radio"
            disabled={item.total_time_in_days > 0}
            icon=""
            options={{
              name: null,
            }}
            onChange={() => {
              addToGroupDelete(item.id)
            }}
          />
          <img
            aria-label="image delete"
            src={pictoDestroy}
          />
        </div>
      )
      : (<div className="delete-mode-hide" />)
    ),
  }
  const columnsDefault = [
    {
      name: 'role',
      title: t('project.activities.role'),
      onRender: ({ name, item }) => (isEditable
        ? (
          <div>
            <InputSelect
              className="role"
              skin="light"
              name="role input"
              valueInitial={item['role_id']}
              options={roles}
              localization={{ validation: { text: 'Sauvegarder', arialabel: 'sauvegarder' } }}
              multiple={false}
              onChange={([value]) => updateActivity(name, 'role_id', value)}
            />
          </div>
        )
        : (<span>{item['role']?.name}</span>)
      ),
    },
    {
      name: 'people',
      title: t('project.activities.people'),
      onRender: ({ name, item }) => (activityPerson(name, item)),
    },
    {
      name: 'profile',
      title: t('project.activities.profile'),
      onRender: ({ name, item }) => (isEditable
        ? (
          <div>
            <InputSelect
              className="role"
              skin="light"
              name="profile input"
              valueInitial={item['profile_id']}
              options={profiles}
              localization={{ validation: { text: 'Sauvegarder', arialabel: 'sauvegarder' } }}
              multiple={false}
              onChange={([value]) => updateActivity(name, 'profile_id', value)}
            />
          </div>
        )
        : (<span>{item['profile']?.name}</span>)
      ),
    },
    {
      name: 'in_idf',
      title: t('project.activities.in_idf'),
      onRender: ({ item }) => (
        (item['people']?.[0] === undefined && '-')
        || (item['people']?.[0] && item['people'][0]['in_idf'] && <img aria-label={`person ${item['people']?.[0]?.nickname} is in idf`} className="is-in-idf" src={pictoYes} />)
        || (<img aria-label={`person ${item['people']?.[0]?.nickname} is not in idf`} className="is-not-in-idf" src={pictoNo} />)
      ),
    },
    {
      name: 'average_daily_rate',
      title: t('project.activities.average_daily_rate'),
      className: 'align-right',
      onRender: ({ name, item }) => (
        <span className="unit price">
          {
              isEditable
                ? (
                  <InputNumber
                    className="input-with-unit"
                    skin="light"
                    name="average_daily_rate"
                    localization={{
                      invalid: { text: '', arialabel: '' },
                    }}
                    disabled={project?.mission_maker === 'acn_prime' || project?.mission_maker === 'acn_prime_by_octo'}
                    valueInitial={item['average_daily_rate']}
                    onChange={(value) => { debounce(() => updateActivity(name, 'average_daily_rate', value), 1000) }}
                  />
                )
                : getNumberWithUnit(item['average_daily_rate'], '€')
            }
        </span>
      ),
    },
    {
      name: 'nb_days',
      title: t('project.activities.nb_days'),
      className: 'align-right',
      onRender: ({ name, item }) => (
        <span className="unit days">
          {
              isEditable
                ? (
                  <InputNumber
                    skin="light"
                    name="nb_days"
                    localization={{
                      invalid: { text: '', arialabel: '' },
                    }}
                    valueInitial={item['nb_days']}
                    onChange={(value) => { debounce(() => updateActivity(name, 'nb_days', value), 1000) }}
                  />
                )
                : getRoundedToDecimal(item['nb_days'], 1)
            }
        </span>
      ),
      onFooter: ({ items }) => getRoundedToDecimal(items.reduce((sum, item) => sum + item['nb_days'], 0), 1),
    },
    {
      name: 'total',
      className: 'align-right',
      title: t('project.activities.total'),
      onRender: ({ item }) => <span className="unit price">{getNumberWithUnit(item['total'], '€')}</span>,
      onFooter: ({ items }) => <span className="unit price">{getNumberWithUnit(items.reduce((sum, item) => sum + item['total'], 0), '€')}</span>,
    },
    {
      name: 'staffing_date',
      title: t('project.activities.staffingDate'),
      onRender: ({ name, item }) => (
        isEditable ? (
          <InputDate
            skin="light"
            name={t('project.activities.staffingDate')}
            valueInitial={item['staffing_needed_from']}
            onChange={(value) => updateActivity(name, 'staffing_needed_from', value)}
            precision="day"
            localization={{
              months: [...monthList.map((month) => t(`months.${month}.short`)), ...monthList.map((month) => t(`months.${month}.full`))],
              previous: t('project.coordination.expenses.month.previous'),
              next: t('project.coordination.expenses.month.next'),
            }}
          />
        ) : ((item['staffing_needed_from'] && getYearMonthDayFromDateString(item['staffing_needed_from'])) || '-')
      ),
    },
    {
      name: 'comment',
      title: t('project.staffing.form.comment'),
      onRender: ({ name, item }) => (
        isEditable
          ? (
            <InputText
              skin="light"
              name="expertise"
              localization={{
                invalid: { text: '', arialabel: '' },
              }}
              valueInitial={item['expertise']}
              onChange={(value) => updateActivity(name, 'expertise', value)}
            />
          )
          : item['expertise'] || '-'
      ),
    },
    {
      name: 'expertise',
      title: t('project.staffing.form.expertise'),
      onRender: ({ name, item }) => (
        <ProjectSelectSkillsActivity compact activity={item} name={name} updateActivity={updateActivity} isEditable={isEditable} />
      ),
    },
  ]

  if (project?.nature === 'training') {
    columnsDefault.pop()
    columnsDefault.unshift(columnsDefault[columnsDefault.length - 1])
    columnsDefault.pop()
  }

  const columns = useMemo(() => [
    ...modifyFirstColumn(columnsDefault, deleteColumn, activitiesDeleteMode),
  ], [profiles, roles, isEditable, activitiesDeleteMode])

  if (activities === null) return null

  const copySucceedText = () => (
    <div className="copy-succeed">{t('copySucceed')}</div>
  )

  return (
    <>
      <Button
        className="copy"
        skin="dark"
        kind="primary"
        name={t('clipboard.arialabel')}
        text={t('clipboard.text')}
        icon={{ url: pictoCopy, position: 'right' }}
        onClick={() => copyToClipboard()}
      />
      {copyToClipboardSuccess && copySucceedText()}
      <div ref={table}>
        <Table
          className="table wrapper"
          columns={columns}
          data={activities}
        />
      </div>
      { isProjectPresales() && (
        <>
          <div className="below-table-button">
            { tableActivityDeleteMode
              ? null
              : <ProjectStaffingFormHandler turnoverTypeSelect="from_internal_team" />}
            {
              Object.keys(activities).length > 0
                ? (
                  <ButtonDelete
                    tableActivityDeleteMode={tableActivityDeleteMode}
                    activitiesDeleteMode={activitiesDeleteMode}
                    setActivitiesDeleteMode={setActivitiesDeleteMode}
                    setTableActivityDeleteMode={setTableActivityDeleteMode}
                    setInlineStaffingFormVisible={setInlineStaffingFormVisible}
                    setOpenModal={setOpenModal}
                    fetchDelete={fetchDeleteActivities}
                    isProjectPresales={isProjectPresales}
                  />
                )
                : null
            }
          </div>
          {
            (inlineStaffingFormVisible && !tableActivityDeleteMode && turnoverTypeForm === 'from_internal_team') && (
              <ProjectInlineStaffingForm
                onClose={() => {
                  setExpenseIdToUpdate(0)
                  publish('/octopod/project/update_kpis')
                }}
              />
            )
          }
        </>
      )}
    </>
  )
}

export const ToggleButton = ({ className, initialValue, onChange }) => {
  const [isOn, setIsOn] = useState(initialValue)

  const btnClassCircle = () => (isOn ? 'circle on' : 'circle off')

  return (
    <div
      className={getClass('toggle-btn', !isOn && 'btn-inactive', className)}
      role="button"
      tabIndex={0}
      aria-label="expense activation button"
      onClick={() => {
        setIsOn(!isOn)
        onChange(!isOn)
      }}
      onKeyDown={() => {
        setIsOn(!isOn)
        onChange(!isOn)
      }}
    >
      <div className={btnClassCircle()} />
    </div>
  )
}

export const ProjectSubcontractingExpenses = ({ isEditable, presalesExpenses, setOpenModal }) => {
  const { t } = useTranslation()
  const { debounce } = useDebouncer()
  const { publish } = useJQuery()
  const {
    subcontractingProjectExpenses,
    projectSnapshot,
    fetchGetSubcontractors,
    fetchPutProjectExpenses,
    addToGroupDelete,
    fetchDeleteProjectExpense,
    tableActivityDeleteMode,
    setTableActivityDeleteMode,
    isProjectPresales,
    inlineStaffingFormVisible,
    setInlineStaffingFormVisible,
    turnoverTypeForm,
    setExpenseIdToUpdate,
  } = useProjectSalesContext()
  const [subcontractingExpenses, setSubcontractingExpenses] = useState(null)
  const [copyToClipboardSuccess, setCopyToClipboardSuccess] = useState(false)
  const table = useRef()
  const [activitiesDeleteMode, setActivitiesDeleteMode] = useState(false)

  useEffect(() => {
    const expenses = (projectSnapshot?.project_expenses ? presalesExpenses : null) || subcontractingProjectExpenses

    setSubcontractingExpenses(Object.fromEntries(expenses.map((expense) => [expense.id, {
      ...expense,
      'unit_price': getRoundedToDecimal(parseFloat(expense['unit_price']), 2),
      'markup': getRoundedToDecimal(parseFloat(expense['markup']), 2),
      'selling_price': getRoundedToDecimal(parseFloat(expense['selling_price']), 2),
      'quantity': getRoundedToDecimal(parseFloat(expense['quantity']), 2),
      'total': getRoundedToDecimal(parseFloat(expense['selling_price']) * parseFloat(expense['quantity']), 2),
    }])))
  }, [subcontractingProjectExpenses, projectSnapshot])

  const copyToClipboard = () => {
    if (table.current.childNodes[0]) {
      const nodes = Array.from(table.current.childNodes[0].childNodes)
        .flatMap((child) => Array.from(child.childNodes)).flatMap((child) => Array.from(child.childNodes))
        .map((line) => Array.from(line.childNodes))
      const header = nodes[0].map((cell) => cell.textContent)
      header.splice(1, 0, t('project.costs.projectExpenses.active'))
      const footer = nodes[nodes.length - 1].map((cell) => cell.textContent)
      footer.splice(1, 0, '')

      let lines = []
      if (nodes.length > 2) {
        lines = nodes.slice(1, nodes.length - 1)
          .map((line) => line.map((cell, index) => {
            if (index === 0) {
              const isActiveOnMission = cell.getElementsByClassName('circle on')[0] ? t('yes') : t('no')
              return [cell.textContent, isActiveOnMission].join('\t')
            }
            const input = cell.getElementsByTagName('input')[0]
            return ((input && input.value) || (cell.textContent))
          }).join('\t'))
      }

      navigator.clipboard.writeText([header.join('\t'), ...lines, footer.join('\t')].join('\n'))
    }
    setCopyToClipboardSuccess(true)
    setTimeout(() => {
      setCopyToClipboardSuccess(false)
    }, 5000)
  }

  const updateExpense = (expense, attributName, attributValue) => setSubcontractingExpenses((previous) => {
    const updatedStatus = { ...previous }
    const expenseId = expense['id']
    updatedStatus[expenseId][attributName] = attributValue
    updatedStatus[expenseId]['total'] = getRoundedToDecimal(parseFloat(expense['selling_price']) * parseFloat(expense['quantity']), 2)
    const filteredExpense = Object.fromEntries(Object.entries(updatedStatus[expenseId]).filter((attribut) => attribut[0] !== 'total'))
    fetchPutProjectExpenses(filteredExpense)

    return updatedStatus
  })

  const deleteColumn = {
    name: 'delete_activity',
    title: `${activitiesDeleteMode ? t('project.activities.delete') : ''}`,
    onRender: ({ item }) => (activitiesDeleteMode
      ? (
        <div className="input-radio-destroy">
          <InputRadio
            multiple="false"
            className="form-control radio radio-inline button-delete-activity"
            skin="light"
            name="delete_activity radio"
            disabled={totalMonthsExpenseQuantities(item.project_expense_quantities) > 0}
            icon=""
            options={{
              name: null,
            }}
            onChange={() => {
              addToGroupDelete(item.id)
            }}
          />
          <img
            aria-label="image delete"
            src={pictoDestroy}
          />
        </div>
      )
      : (<div className="delete-mode-hide" />)
    ),
  }

  const columnsDefault = [
    {
      name: 'subcontractor_name',
      title: t('project.costs.projectExpenses.subcontractorName'),
      onRender: ({ item }) => (isEditable
        ? (
          <div>
            <InputSearch
              className="subcontractor-search"
              skin="light"
              name="subcontractor_name"
              valueInitial={{ key: item['subcontractor_id'], search: item['subcontractor_name'] }}
              onSearch={async (search) => {
                const subcontractors = await fetchGetSubcontractors()
                return Object.fromEntries(Object.entries(subcontractors).filter(([, value]) => value.toLowerCase().includes(search.toLowerCase())))
              }}
              onChange={(value) => {
                if (value['key'] !== null) {
                  updateExpense(item, 'subcontractor_id', parseInt(value['key'], 10))
                }
              }}
            />
            <div className="expense-status">
              <ToggleButton
                className="expense-status-toggle"
                initialValue={item['active']}
                onChange={(status) => {
                  updateExpense(item, 'active', status)
                }}
              />
              <span>{item['active'] ? t('project.costs.projectExpenses.active') : t('project.costs.projectExpenses.inactive')}</span>
            </div>
          </div>
        )
        : (<span>{item['subcontractor_name']}</span>)
      ),
      onFooter: () => <span className="total">{ t('project.costs.projectExpenses.total')}</span>,
    },
    {
      name: 'label',
      title: t('project.costs.projectExpenses.activity'),
      onRender: ({ item }) => (isEditable
        ? (
          <InputText
            skin="light"
            name="label"
            valueInitial={item['label']}
            onChange={(value) => value.length > 0 && debounce(() => updateExpense(item, 'label', value), 1000)}
            onCheck={(value) => value.length <= 0 && `${t('expenses.errors.cantBeBlank')}`}
          />
        )
        : (item['label'] || '-')),
    },
    {
      name: 'purchase_order',
      title: t('project.costs.projectExpenses.purchaseOrder'),
      onRender: ({ item }) => (isEditable
        ? (
          <InputText
            className="purchase-order"
            skin="light"
            name="purchase_order"
            valueInitial={item['purchase_order']}
            onChange={(value) => debounce(() => updateExpense(item, 'purchase_order', value), 1000)}
          />
        )
        : (item['purchase_order'] || '-')),
    },
    {
      name: 'company_name',
      title: t('project.costs.projectExpenses.company'),
      onRender: ({ item }) => item['company_name'] || '-',
    },
    {
      name: 'unit_price',
      className: 'align-right',
      title: t('project.costs.projectExpenses.unitPrice'),
      onRender: ({ item }) => (
        <span className="unit price">
          {
          isEditable
            ? (
              <>
                <InputNumber
                  className="unit-price input-with-unit"
                  skin="light"
                  name="unit_price"
                  valueInitial={item['unit_price']}
                  localization={{
                    invalid: { text: t('expenses.errors.mustBeANumber'), arialabel: t('expenses.errors.mustBeANumber') },
                  }}
                  onChange={(value) => value >= 0 && debounce(() => updateExpense(item, 'unit_price', value), 1000)}
                  onCheck={(value) => value < 0 && `${t('expenses.errors.greaterThanOrEqualToZero')}`}
                />
              </>
            )
            : getNumberWithUnit(getRoundedToDecimal(item['unit_price'], 2), '€')
        }
        </span>
      ),
    },
    {
      name: 'markup',
      className: 'align-right',
      title: t('project.costs.projectExpenses.markup'),
      onRender: ({ item }) => (
        <span className="unit price">
          {
          isEditable
            ? (
              <>
                <InputNumber
                  className="markup input-with-unit"
                  skin="light"
                  name="markup"
                  localization={{
                    invalid: { text: t('expenses.errors.mustBeANumber'), arialabel: t('expenses.errors.mustBeANumber') },
                  }}
                  valueInitial={item['markup']}
                  onChange={(value) => debounce(() => updateExpense(item, 'markup', value), 1000)}
                />
              </>
            )
            : getNumberWithUnit(getRoundedToDecimal(item['markup'], 2), '€')
        }
        </span>
      ),
    },
    {
      name: 'selling_price',
      className: 'align-right',
      title: t('project.costs.projectExpenses.sellingPrice'),
      onRender: ({ item }) => <span>{getNumberWithUnit(parseFloat(item['selling_price']), '€')}</span>,
    },
    {
      name: 'quantity',
      className: 'align-right',
      title: t('project.costs.projectExpenses.nbDays'),
      onRender: ({ item }) => (
        <span className="unit">
          {
          isEditable
            ? (
              <>
                <InputNumber
                  className="quantity"
                  skin="light"
                  name="quantity"
                  valueInitial={item['quantity']}
                  localization={{
                    invalid: { text: t('expenses.errors.mustBeANumber'), arialabel: t('expenses.errors.mustBeANumber') },
                  }}
                  onChange={(value) => value >= 0 && debounce(() => updateExpense(item, 'quantity', value), 1000)}
                  onCheck={(value) => value < 0 && `${t('expenses.errors.greaterThanOrEqualToZero')}`}
                />
              </>
            )
            : getRoundedToDecimal(item['quantity'], 1)
        }
        </span>
      ),
      onFooter: ({ items }) => <span className="total">{getRoundedToDecimal(items.reduce((sum, item) => sum + item['quantity'], 0), 1)}</span>,
    },
    {
      name: 'total',
      className: 'align-right',
      title: t('project.costs.projectExpenses.totalPrice'),
      onRender: ({ item }) => <span>{getNumberWithUnit(parseFloat(item['total']), '€')}</span>,
      onFooter: ({ items }) => <span className="total">{getNumberWithUnit(getRoundedToDecimal(items.reduce((sum, item) => sum + item['total'], 0), 2), '€')}</span>,
    },
  ]

  const columns = useMemo(() => [
    ...modifyFirstColumn(columnsDefault, deleteColumn, activitiesDeleteMode),
  ], [isEditable, activitiesDeleteMode])

  const copySucceedText = () => (
    <div className="copy-succeed">{t('copySucceed')}</div>
  )

  if (subcontractingExpenses === null) return null
  return (
    <>
      <Button
        className="copy"
        skin="dark"
        kind="primary"
        name={t('clipboard.arialabel')}
        text={t('clipboard.text')}
        icon={{ url: pictoCopy, position: 'right' }}
        onClick={() => copyToClipboard()}
      />
      {copyToClipboardSuccess && copySucceedText()}
      <div ref={table}>
        <Table className="table subcontracting-table" columns={columns} data={subcontractingExpenses} />
      </div>
      { isProjectPresales() && (
        <>
          <div className="below-table-button">
            { tableActivityDeleteMode
              ? null
              : <ProjectStaffingFormHandler turnoverTypeSelect="subcontracting" />}
            {
              Object.keys(subcontractingExpenses).length > 0
                ? (
                  <ButtonDelete
                    tableActivityDeleteMode={tableActivityDeleteMode}
                    activitiesDeleteMode={activitiesDeleteMode}
                    setActivitiesDeleteMode={setActivitiesDeleteMode}
                    setTableActivityDeleteMode={setTableActivityDeleteMode}
                    setInlineStaffingFormVisible={setInlineStaffingFormVisible}
                    setOpenModal={setOpenModal}
                    fetchDelete={fetchDeleteProjectExpense}
                    isProjectPresales={isProjectPresales}
                  />
                )
                : null

              }
          </div>
          {
            (inlineStaffingFormVisible && !tableActivityDeleteMode && turnoverTypeForm === 'subcontracting') && (
              <ProjectInlineStaffingForm
                onClose={() => {
                  setExpenseIdToUpdate(0)
                  publish('/octopod/project/update_kpis')
                }}
              />
            )
          }
        </>
      )}
    </>
  )
}

export const ProjectIntragroupExpenses = ({ isEditable, presalesExpenses, setOpenModal }) => {
  const { t } = useTranslation()
  const { debounce } = useDebouncer()
  const { publish } = useJQuery()
  const {
    intragroupProjectExpenses,
    projectSnapshot,
    fetchPutProjectExpenses,
    addToGroupDelete,
    fetchDeleteProjectExpense,
    tableActivityDeleteMode,
    setTableActivityDeleteMode,
    isProjectPresales,
    inlineStaffingFormVisible,
    setInlineStaffingFormVisible,
    turnoverTypeForm,
    setExpenseIdToUpdate,
  } = useProjectSalesContext()
  const [intragroupExpenses, setIntragroupExpenses] = useState(null)
  const [copyToClipboardSuccess, setCopyToClipboardSuccess] = useState(false)
  const table = useRef()
  const [activitiesDeleteMode, setActivitiesDeleteMode] = useState(false)

  useEffect(() => {
    const expenses = (projectSnapshot?.project_expenses ? presalesExpenses : null) || intragroupProjectExpenses

    setIntragroupExpenses(Object.fromEntries(expenses.map((expense) => [expense.id, {
      ...expense,
      'unit_price': getRoundedToDecimal(parseFloat(expense['unit_price']), 2),
      'markup': getRoundedToDecimal(parseFloat(expense['markup']), 2),
      'selling_price': getRoundedToDecimal(parseFloat(expense['selling_price']), 2),
      'quantity': getRoundedToDecimal(parseFloat(expense['quantity']), 2),
      'total': getRoundedToDecimal(parseFloat(expense['selling_price']) * parseFloat(expense['quantity']), 2),
    }])))
  }, [intragroupProjectExpenses, projectSnapshot])

  const copyToClipboard = () => {
    if (table.current.childNodes[0]) {
      const nodes = Array.from(table.current.childNodes[0].childNodes)
        .flatMap((child) => Array.from(child.childNodes)).flatMap((child) => Array.from(child.childNodes))
        .map((line) => Array.from(line.childNodes))
      const header = nodes[0].map((cell) => cell.textContent)
      const footer = nodes[nodes.length - 1].map((cell) => cell.textContent)

      let lines = []
      if (nodes.length > 2) {
        lines = nodes.slice(1, nodes.length - 1)
          .map((line) => line.map((cell) => {
            const input = cell.getElementsByTagName('input')[0]
            return ((input && input.value) || (cell.textContent)
            )
          }).join('\t'))
      }

      navigator.clipboard.writeText([header.join('\t'), ...lines, footer.join('\t')].join('\n'))
    }
    setCopyToClipboardSuccess(true)
    setTimeout(() => {
      setCopyToClipboardSuccess(false)
    }, 5000)
  }

  const updateExpense = (expense, attributName, attributValue) => setIntragroupExpenses((previous) => {
    const updatedStatus = { ...previous }
    const expenseId = expense['id']
    updatedStatus[expenseId][attributName] = attributValue
    updatedStatus[expenseId]['total'] = getRoundedToDecimal(parseFloat(expense['selling_price']) * parseFloat(expense['quantity']), 2)
    const filteredExpense = Object.fromEntries(Object.entries(updatedStatus[expenseId]).filter((attribut) => attribut[0] !== 'total'))
    fetchPutProjectExpenses(filteredExpense)

    return updatedStatus
  })

  const deleteColumn = {
    name: 'delete_activity',
    title: `${activitiesDeleteMode ? t('project.activities.delete') : ''}`,
    onRender: ({ item }) => (activitiesDeleteMode
      ? (
        <div className="input-radio-destroy">
          <InputRadio
            multiple="false"
            className="form-control radio radio-inline button-delete-activity"
            skin="light"
            name="delete_activity radio"
            disabled={totalMonthsExpenseQuantities(item.project_expense_quantities) > 0}
            icon=""
            options={{
              name: null,
            }}
            onChange={() => {
              addToGroupDelete(item.id)
            }}
          />
          <img
            aria-label="image delete"
            src={pictoDestroy}
          />
        </div>
      )
      : (<div className="delete-mode-hide" />)
    ),
  }

  const columnsDefault = [
    {
      name: 'subcontractor_name',
      title: t('project.costs.projectExpenses.subcontractorName'),
      onRender: ({ item }) => (isEditable
        ? (
          <InputText
            skin="light"
            name="label"
            valueInitial={item['subcontractor_name']}
            onChange={(value) => debounce(() => updateExpense(item, 'subcontractor_name', value), 1000)}
            onCheck={(value) => value.length <= 0 && `${t('expenses.errors.cantBeBlank')}`}
          />
        )
        : (item['subcontractor_name'] || '-')),
      onFooter: () => <span className="total">{ t('project.costs.projectExpenses.total')}</span>,
    },
    {
      name: 'label',
      title: t('project.costs.projectExpenses.activity'),
      onRender: ({ item }) => (isEditable
        ? (
          <InputText
            skin="light"
            name="label"
            valueInitial={item['label']}
            onChange={(value) => debounce(() => updateExpense(item, 'label', value), 1000)}
            onCheck={(value) => value.length <= 0 && `${t('expenses.errors.cantBeBlank')}`}
          />
        )
        : (item['label'] || '-')),
    },
    {
      name: 'unit_price',
      className: 'align-right',
      title: t('project.costs.projectExpenses.unitPrice'),
      onRender: ({ item }) => (
        <span className="unit price">
          {
          isEditable
            ? (
              <>
                <InputNumber
                  className="input-with-unit"
                  skin="light"
                  name="unit_price"
                  valueInitial={item['unit_price']}
                  localization={{
                    invalid: { text: t('expenses.errors.mustBeANumber'), arialabel: t('expenses.errors.mustBeANumber') },
                  }}
                  onChange={(value) => debounce(() => updateExpense(item, 'unit_price', value), 1000)}
                  onCheck={(value) => value < 0 && `${t('expenses.errors.greaterThanOrEqualToZero')}`}
                />
              </>
            )
            : getNumberWithUnit(getRoundedToDecimal(item['unit_price'], 2), '€')
        }
        </span>
      ),
    },
    {
      name: 'markup',
      className: 'align-right',
      title: t('project.costs.projectExpenses.markup'),
      onRender: ({ item }) => (
        <span className="unit price">
          {
          isEditable
            ? (
              <>
                <InputNumber
                  className="input-with-unit"
                  skin="light"
                  name="markup"
                  localization={{
                    invalid: { text: t('expenses.errors.mustBeANumber'), arialabel: t('expenses.errors.mustBeANumber') },
                  }}
                  valueInitial={item['markup']}
                  onChange={(value) => debounce(() => updateExpense(item, 'markup', value), 1000)}
                />
              </>
            )
            : getNumberWithUnit(getRoundedToDecimal(item['markup'], 2), '€')
        }
        </span>
      ),
    },
    {
      name: 'selling_price',
      className: 'align-right',
      title: t('project.costs.projectExpenses.sellingPrice'),
      onRender: ({ item }) => <span>{getNumberWithUnit(getRoundedToDecimal(item['selling_price'], 2), '€')}</span>,
    },
    {
      name: 'quantity',
      className: 'align-right',
      title: t('project.costs.projectExpenses.nbDays'),
      onRender: ({ item }) => (
        <span className="unit">
          {
          isEditable
            ? (
              <>
                <InputNumber
                  skin="light"
                  name="quantity"
                  localization={{
                    invalid: { text: t('expenses.errors.mustBeANumber'), arialabel: t('expenses.errors.mustBeANumber') },
                  }}
                  valueInitial={item['quantity']}
                  onChange={(value) => debounce(() => updateExpense(item, 'quantity', value), 1000)}
                  onCheck={(value) => value < 0 && `${t('expenses.errors.greaterThanOrEqualToZero')}`}
                />
              </>
            )
            : getRoundedToDecimal(item['quantity'], 1)
        }
        </span>
      ),
      onFooter: ({ items }) => <span className="total">{getRoundedToDecimal(items.reduce((sum, item) => sum + item['quantity'], 0), 1)}</span>,
    },
    {
      name: 'total',
      className: 'align-right',
      title: t('project.costs.projectExpenses.totalPrice'),
      onRender: ({ item }) => <span>{getNumberWithUnit(getRoundedToDecimal(item['total'], 2), '€')}</span>,
      onFooter: ({ items }) => <span className="total">{getNumberWithUnit(getRoundedToDecimal(items.reduce((sum, item) => sum + item['total'], 0), 2), '€')}</span>,
    },
  ]

  const columns = useMemo(() => [
    ...modifyFirstColumn(columnsDefault, deleteColumn, activitiesDeleteMode),
  ], [isEditable, activitiesDeleteMode])

  const copySucceedText = () => (
    <div className="copy-succeed">{t('copySucceed')}</div>
  )

  if (intragroupExpenses === null) return null
  return (
    <>
      <Button
        className="copy"
        skin="dark"
        kind="primary"
        name={t('clipboard.arialabel')}
        text={t('clipboard.text')}
        icon={{ url: pictoCopy, position: 'right' }}
        onClick={() => copyToClipboard()}
      />
      {copyToClipboardSuccess && copySucceedText()}
      <div ref={table}>
        <Table className="table intragroup-table" columns={columns} data={intragroupExpenses} />
      </div>
      { isProjectPresales() && (
        <>
          <div className="below-table-button">
            { tableActivityDeleteMode
              ? null
              : <ProjectStaffingFormHandler turnoverTypeSelect="intragroup" />}
            {
              Object.keys(intragroupExpenses).length > 0
                ? (
                  <ButtonDelete
                    tableActivityDeleteMode={tableActivityDeleteMode}
                    activitiesDeleteMode={activitiesDeleteMode}
                    setActivitiesDeleteMode={setActivitiesDeleteMode}
                    setTableActivityDeleteMode={setTableActivityDeleteMode}
                    setInlineStaffingFormVisible={setInlineStaffingFormVisible}
                    setOpenModal={setOpenModal}
                    fetchDelete={fetchDeleteProjectExpense}
                    isProjectPresales={isProjectPresales}
                  />
                )
                : null
            }
          </div>
          {
            (inlineStaffingFormVisible && !tableActivityDeleteMode && turnoverTypeForm === 'intragroup') && (
              <ProjectInlineStaffingForm
                onClose={() => {
                  setExpenseIdToUpdate(0)
                  publish('/octopod/project/update_kpis')
                }}
              />
            )
          }
        </>
      )}
    </>
  )
}

export const ProjectBenextExpenses = ({ presalesExpenses, setOpenModal }) => {
  const { t } = useTranslation()
  const {
    benextProjectExpenses,
    projectSnapshot,
    addToGroupDelete,
    fetchDeleteProjectExpense,
    tableActivityDeleteMode,
    setTableActivityDeleteMode,
    isProjectPresales,
  } = useProjectSalesContext()
  const [benextExpenses, setBenextExpenses] = useState(null)
  const [copyToClipboardSuccess, setCopyToClipboardSuccess] = useState(false)
  const table = useRef()
  const [activitiesDeleteMode, setActivitiesDeleteMode] = useState(false)

  useEffect(() => {
    const expenses = (projectSnapshot?.project_expenses ? presalesExpenses : null) || benextProjectExpenses

    setBenextExpenses(Object.fromEntries(expenses.map((expense) => [expense.id, {
      ...expense,
      'unit_price': getRoundedToDecimal(parseFloat(expense['unit_price']), 2),
      'markup': getRoundedToDecimal(parseFloat(expense['markup']), 2),
      'selling_price': getRoundedToDecimal(parseFloat(expense['selling_price']), 2),
      'quantity': getRoundedToDecimal(parseFloat(expense['quantity']), 2),
      'total': getRoundedToDecimal(parseFloat(expense['selling_price']) * parseFloat(expense['quantity']), 2),
    }])))
  }, [benextProjectExpenses, projectSnapshot])

  const copyToClipboard = () => {
    if (table.current.childNodes[0]) {
      const nodes = Array.from(table.current.childNodes[0].childNodes)
        .flatMap((child) => Array.from(child.childNodes)).flatMap((child) => Array.from(child.childNodes))
        .map((line) => Array.from(line.childNodes))
      const header = nodes[0].map((cell) => cell.textContent)
      const footer = nodes[nodes.length - 1].map((cell) => cell.textContent)

      let lines = []
      if (nodes.length > 2) {
        lines = nodes.slice(1, nodes.length - 1)
          .map((line) => line.map((cell) => cell.textContent).join('\t'))
      }

      navigator.clipboard.writeText([header.join('\t'), ...lines, footer.join('\t')].join('\n'))
    }
    setCopyToClipboardSuccess(true)
    setTimeout(() => {
      setCopyToClipboardSuccess(false)
    }, 5000)
  }

  const deleteColumn = {
    name: 'delete_activity',
    title: `${activitiesDeleteMode ? t('project.activities.delete') : ''}`,
    onRender: ({ item }) => (activitiesDeleteMode
      ? (
        <div className="input-radio-destroy">
          <InputRadio
            multiple="false"
            className="form-control radio radio-inline button-delete-activity"
            skin="light"
            name="delete_activity radio"
            disabled={totalMonthsExpenseQuantities(item.project_expense_quantities) > 0}
            icon=""
            options={{
              name: null,
            }}
            onChange={() => {
              addToGroupDelete(item.id)
            }}
          />
          <img
            aria-label="image delete"
            src={pictoDestroy}
          />
        </div>
      )
      : (<div className="delete-mode-hide" />)
    ),
  }

  const columnsDefault = [
    {
      name: 'subcontractor_name',
      title: t('project.costs.projectExpenses.subcontractorName'),
      onRender: ({ item }) => item['subcontractor_name'] || '-',
      onFooter: () => <span className="total">{ t('project.costs.projectExpenses.total')}</span>,
    },
    {
      name: 'label',
      title: t('project.costs.projectExpenses.activity'),
      onRender: ({ item }) => item['label'] || '-',
    },
    {
      name: 'unit_price',
      title: t('project.costs.projectExpenses.unitPrice'),
      onRender: ({ item }) => <span>{getNumberWithUnit(getRoundedToDecimal(item['unit_price'], 2), '€')}</span>,
    },
    {
      name: 'markup',
      title: t('project.costs.projectExpenses.markup'),
      onRender: ({ item }) => <span>{getNumberWithUnit(getRoundedToDecimal(item['markup'], 2), '€')}</span>,
    },
    {
      name: 'selling_price',
      title: t('project.costs.projectExpenses.sellingPrice'),
      onRender: ({ item }) => <span>{getNumberWithUnit(getRoundedToDecimal(item['selling_price'], 2), '€')}</span>,
    },
    {
      name: 'quantity',
      title: t('project.costs.projectExpenses.nbDays'),
      onRender: ({ item }) => <span className="unit">{getRoundedToDecimal(item['quantity'], 1)}</span>,
      onFooter: ({ items }) => <span className="total">{getRoundedToDecimal(items.reduce((sum, item) => sum + item['quantity'], 0), 1)}</span>,
    },
    {
      name: 'total',
      title: t('project.costs.projectExpenses.totalPrice'),
      onRender: ({ item }) => <span>{getNumberWithUnit(getRoundedToDecimal(item['total'], 2), '€')}</span>,
      onFooter: ({ items }) => <span className="total">{getNumberWithUnit(getRoundedToDecimal(items.reduce((sum, item) => sum + item['total'], 0), 2), '€')}</span>,
    },
  ]

  const columns = useMemo(() => [
    ...modifyFirstColumn(columnsDefault, deleteColumn, activitiesDeleteMode),
  ], [activitiesDeleteMode])
  const copySucceedText = () => (
    <div className="copy-succeed">{t('copySucceed')}</div>
  )

  if (benextExpenses === null) return null

  return (
    <>
      <Button
        className="copy"
        skin="dark"
        kind="primary"
        name={t('clipboard.arialabel')}
        text={t('clipboard.text')}
        icon={{ url: pictoCopy, position: 'right' }}
        onClick={() => copyToClipboard()}
      />
      {copyToClipboardSuccess && copySucceedText()}
      <div ref={table}>
        <Table className="table" columns={columns} data={benextExpenses} />
      </div>
      {
        Object.keys(benextExpenses).length > 0
          ? (
            <ButtonDelete
              tableActivityDeleteMode={tableActivityDeleteMode}
              activitiesDeleteMode={activitiesDeleteMode}
              setActivitiesDeleteMode={setActivitiesDeleteMode}
              setTableActivityDeleteMode={setTableActivityDeleteMode}
              setOpenModal={setOpenModal}
              fetchDelete={fetchDeleteProjectExpense}
              isProjectPresales={isProjectPresales}
            />
          )
          : null

      }
    </>
  )
}

export const ProjectMiscExpenses = ({ presalesExpenses, setOpenModal }) => {
  const { t } = useTranslation()
  const {
    miscProjectExpenses,
    projectSnapshot,
    addToGroupDelete,
    fetchDeleteProjectExpense,
    tableActivityDeleteMode,
    setTableActivityDeleteMode,
    isProjectPresales,
  } = useProjectSalesContext()
  const [miscExpenses, setMiscExpenses] = useState(null)
  const [copyToClipboardSuccess, setCopyToClipboardSuccess] = useState(false)
  const table = useRef()
  const [activitiesDeleteMode, setActivitiesDeleteMode] = useState(false)

  useEffect(() => {
    const expenses = (projectSnapshot?.project_expenses ? presalesExpenses : null) || miscProjectExpenses

    setMiscExpenses(Object.fromEntries(expenses.map((expense) => [expense.id, {
      ...expense,
      'unit_price': getRoundedToDecimal(parseFloat(expense['unit_price']), 2),
      'quantity': getRoundedToDecimal(parseFloat(expense['quantity']), 2),
      'total': getRoundedToDecimal(parseFloat(expense['unit_price']) * parseFloat(expense['quantity']), 2),
    }])))
  }, [miscProjectExpenses, projectSnapshot])

  const copyToClipboard = () => {
    if (table.current.childNodes[0]) {
      const nodes = Array.from(table.current.childNodes[0].childNodes)
        .flatMap((child) => Array.from(child.childNodes)).flatMap((child) => Array.from(child.childNodes))
        .map((line) => Array.from(line.childNodes))
      const header = nodes[0].map((cell) => cell.textContent)
      const footer = nodes[nodes.length - 1].map((cell) => cell.textContent)

      let lines = []
      if (nodes.length > 2) {
        lines = nodes.slice(1, nodes.length - 1)
          .map((line) => line.map((cell) => cell.textContent).join('\t'))
      }

      navigator.clipboard.writeText([header.join('\t'), ...lines, footer.join('\t')].join('\n'))
    }
    setCopyToClipboardSuccess(true)
    setTimeout(() => {
      setCopyToClipboardSuccess(false)
    }, 5000)
  }

  const deleteColumn = {
    name: 'delete_activity',
    title: `${activitiesDeleteMode ? t('project.activities.delete') : ''}`,
    onRender: ({ item }) => (activitiesDeleteMode
      ? (
        <div className="input-radio-destroy">
          <InputRadio
            multiple="false"
            className="form-control radio radio-inline button-delete-activity"
            skin="light"
            name="delete_activity radio"
            disabled={totalMonthsExpenseQuantities(item.project_expense_quantities) > 0}
            icon=""
            options={{
              name: null,
            }}
            onChange={() => {
              addToGroupDelete(item.id)
            }}
          />
          <img
            aria-label="image delete"
            src={pictoDestroy}
          />
        </div>
      )
      : (<div className="delete-mode-hide" />)
    ),
  }

  const columnsDefault = [
    {
      name: 'type',
      title: t('project.activities.subcontracting.type'),
      onRender: ({ item }) => <span>{ t(`project.activities.subcontracting.types.${item['turnover_type']}`)}</span>,
      onFooter: () => <span className="total">{ t('project.costs.projectExpenses.total')}</span>,
    },
    {
      name: 'description',
      title: t('project.activities.subcontracting.description'),
      onRender: ({ item }) => <span>{item['label']}</span>,
    },
    {
      name: 'quantity',
      className: 'align-right',
      title: t('project.activities.subcontracting.quantity'),
      onRender: ({ item }) => <span>{item['quantity']}</span>,
      onFooter: ({ items }) => <span className="total">{getRoundedToDecimal(items.reduce((sum, item) => sum + item['quantity'], 0), 1)}</span>,
    },
    {
      name: 'unit_price',
      className: 'align-right',
      title: t('project.activities.subcontracting.price.unit'),
      onRender: ({ item }) => <span>{getNumberWithUnit(getRoundedToDecimal(item['unit_price'], 2), '€')}</span>,
    },
    {
      name: 'total',
      className: 'align-right',
      title: t('project.costs.projectExpenses.totalPrice'),
      onRender: ({ item }) => <span>{getNumberWithUnit(getRoundedToDecimal(item['total'], 2), '€')}</span>,
      onFooter: ({ items }) => <span className="total">{getNumberWithUnit(getRoundedToDecimal(items.reduce((sum, item) => sum + item['total'], 0), 2), '€')}</span>,
    },
  ]

  const columns = useMemo(() => [
    ...modifyFirstColumn(columnsDefault, deleteColumn, activitiesDeleteMode),
  ], [activitiesDeleteMode])
  const copySucceedText = () => (
    <div className="copy-succeed">{t('copySucceed')}</div>
  )

  if (miscExpenses === null) return null
  return (
    <>
      <Button
        className="copy"
        skin="dark"
        kind="primary"
        name={t('clipboard.arialabel')}
        text={t('clipboard.text')}
        icon={{ url: pictoCopy, position: 'right' }}
        onClick={() => copyToClipboard()}
      />
      {copyToClipboardSuccess && copySucceedText()}
      <div ref={table}>
        <Table className="table" columns={columns} data={miscExpenses} />
      </div>
      {
        Object.keys(miscExpenses).length > 0
          ? (
            <ButtonDelete
              tableActivityDeleteMode={tableActivityDeleteMode}
              activitiesDeleteMode={activitiesDeleteMode}
              setActivitiesDeleteMode={setActivitiesDeleteMode}
              setTableActivityDeleteMode={setTableActivityDeleteMode}
              setOpenModal={setOpenModal}
              fetchDelete={fetchDeleteProjectExpense}
              isProjectPresales={isProjectPresales}
            />
          )
          : null

      }
    </>
  )
}

export const ActivitiesTotal = ({ miscPresalesExpenses, subcontractingPresalesExpenses, intragroupPresalesExpenses, benextPresalesExpenses }) => {
  const { t } = useTranslation()
  const {
    projectActivities,
    miscProjectExpenses,
    subcontractingProjectExpenses,
    intragroupProjectExpenses,
    benextProjectExpenses,
    projectSnapshot,
  } = useProjectSalesContext()

  const [miscTotal, setMiscTotal] = useState(null)
  const [subcontractingTotal, setSubcontractingTotal] = useState(null)
  const [activitiesTotal, setActivitiesTotal] = useState(null)
  const [intragroupTotal, setIntragroupTotal] = useState(null)
  const [benextTotal, setBenextTotal] = useState(null)

  const calculateMiscTotal = (miscExpenses) => {
    let totalAmount = 0
    Object.entries(miscExpenses).map((expense) => {
      const total = getRoundedToDecimal(parseFloat(expense[1]['unit_price']) * parseFloat(expense[1]['quantity']), 2)
      totalAmount += total
      return totalAmount
    })
    return totalAmount
  }

  const calculateSubcontractingTotal = (subcontractingExpenses) => {
    let totalAmount = 0
    Object.entries(subcontractingExpenses).map((expense) => {
      const total = getRoundedToDecimal(parseFloat(expense[1]['selling_price']) * parseFloat(expense[1]['quantity']), 2)
      totalAmount += total
      return totalAmount
    })
    return totalAmount
  }

  const calculateActivitiesTotal = (activities) => {
    let totalAmount = 0
    Object.entries(activities).map((activity) => {
      const total = getRoundedToDecimal(parseFloat(activity[1]['average_daily_rate']) * parseFloat(activity[1]['nb_days']), 2)
      totalAmount += total
      return totalAmount
    })
    return totalAmount
  }

  useEffect(() => {
    const miscExpenses = (projectSnapshot?.project_expenses ? miscPresalesExpenses : null) || miscProjectExpenses
    const subcontractingExpenses = (projectSnapshot?.project_expenses ? subcontractingPresalesExpenses : null) || subcontractingProjectExpenses
    const activities = projectSnapshot?.activities || projectActivities
    const intragroupExpenses = (projectSnapshot?.project_expenses ? intragroupPresalesExpenses : null) || intragroupProjectExpenses
    const benextExpenses = (projectSnapshot?.project_expenses ? benextPresalesExpenses : null) || benextProjectExpenses

    setActivitiesTotal(calculateActivitiesTotal(activities))
    setMiscTotal(calculateMiscTotal(miscExpenses))
    setSubcontractingTotal(calculateSubcontractingTotal(subcontractingExpenses))
    setIntragroupTotal(calculateSubcontractingTotal(intragroupExpenses))
    setBenextTotal(calculateSubcontractingTotal(benextExpenses))
  }, [miscProjectExpenses, subcontractingProjectExpenses, projectActivities, intragroupProjectExpenses, benextProjectExpenses, projectSnapshot])

  const calculateTotal = () => activitiesTotal + miscTotal + subcontractingTotal + intragroupTotal + benextTotal

  return (
    <Section className="total-section">
      <div className="activities-total">
        <p>
          {t('project.costs.projectExpenses.activitiesTotal')}
          <b>{getNumberWithUnit(calculateTotal(), '€')}</b>
        </p>
      </div>
    </Section>
  )
}

const ButtonDelete = ({
  tableActivityDeleteMode,
  activitiesDeleteMode,
  setActivitiesDeleteMode,
  setTableActivityDeleteMode,
  setInlineStaffingFormVisible,
  setOpenModal,
  fetchDelete,
  isProjectPresales,
}) => {
  const { t } = useTranslation()
  return (
    <div>
      <Button
        className="delete-activity"
        skin={activitiesDeleteMode ? 'dark' : 'light'}
        kind={activitiesDeleteMode ? 'primary' : 'secondary'}
        name={t('project.activities.delete_activities')}
        disabled={(tableActivityDeleteMode && !activitiesDeleteMode) || !isProjectPresales()}
        text={activitiesDeleteMode ? t('project.activities.delete_activities_selected') : t('project.activities.delete_activities')}
        onClick={() => {
          if (activitiesDeleteMode) {
            fetchDelete()
            setActivitiesDeleteMode(false)
            setTableActivityDeleteMode(false)
            setInlineStaffingFormVisible(false)
          } else {
            setOpenModal(true)
            setActivitiesDeleteMode(true)
            setTableActivityDeleteMode(true)
          }
        }}
      />
      {
          activitiesDeleteMode
            ? (
              <Button
                className="cancel"
                skin="light"
                kind="secondary"
                name={t('project.form.cancel.arialabel')}
                text={t('project.form.cancel.text')}
                onClick={() => {
                  setActivitiesDeleteMode(false)
                  setTableActivityDeleteMode(false)
                  setInlineStaffingFormVisible(false)
                }}
              />
            )
            : null
        }
    </div>
  )
}

const Modal = ({ setOpenModal }) => (
  <div className="empty-background-modal">
    <div className="container-modal">
      <div className="header-modal">
        <div className="title">Pointages</div>
        <div
          className="picto-close"
          role="button"
          onClick={() => {
            setOpenModal(false)
          }}
          onKeyDown={() => {
            setOpenModal(false)
          }}
          tabIndex={0}
        >
          <img
            aria-label="link description mission"
            src={pictoNavigateCross}
          />
        </div>
      </div>
      <div className="content-modal">
        <div className="text">Les personnes ayant pointés sur le projet ne peuvent pas être supprimés.</div>
      </div>
    </div>
  </div>
)

export const ProjectSalesActivities = () => {
  const { t } = useTranslation()
  const { projectSnapshot, isProjectPresales, benextProjectExpenses, miscProjectExpenses } = useProjectSalesContext()

  const isEditable = !projectSnapshot || isProjectPresales()
  const [openModal, setOpenModal] = useState(false)

  const presalesExpenses = projectSnapshot ? projectSnapshot['project_expenses'] : []

  const [expensesSubcontracting, miscPresalesExpenses] = presalesExpenses.reduce((result, expense) => {
    const isSubcontracting = ['intragroup', 'subcontracting', 'benext_subcontracting'].includes(expense['turnover_type'])
    return result[isSubcontracting ? 0 : 1].push(expense) && result
  }, [[], []])

  const subcontractingPresalesExpenses = expensesSubcontracting.filter((e) => e['turnover_type'] === 'subcontracting')
  const intragroupPresalesExpenses = expensesSubcontracting.filter((e) => e['turnover_type'] === 'intragroup')
  const benextPresalesExpenses = expensesSubcontracting.filter((e) => e['turnover_type'] === 'benext_subcontracting')

  return (
    <ProjectSalesActivitiesStyled className="octo">

      <h2>{t('project.activities.subtitles.activity')}</h2>
      <ProjectSalesActivitiesTable isEditable={isEditable} setOpenModal={setOpenModal} />
      <h2>{t('project.activities.subtitles.intragroup')}</h2>
      <ProjectIntragroupExpenses isEditable={isEditable} presalesExpenses={intragroupPresalesExpenses} setOpenModal={setOpenModal} />
      <h2>{t('project.activities.subtitles.subcontracting')}</h2>
      <ProjectSubcontractingExpenses isEditable={isEditable} presalesExpenses={subcontractingPresalesExpenses} setOpenModal={setOpenModal} />
      {
        (benextPresalesExpenses.length > 0 || benextProjectExpenses.length > 0) && (
          <>
            <h2>{t('project.activities.subtitles.benext')}</h2>
            <ProjectBenextExpenses presalesExpenses={benextPresalesExpenses} setOpenModal={setOpenModal} />
          </>
        )
      }
      {
       (miscPresalesExpenses.length > 0 || miscProjectExpenses.length > 0) && (
       <>
         <h2>{t('project.activities.subtitles.misc')}</h2>
         <ProjectMiscExpenses presalesExpenses={miscPresalesExpenses} setOpenModal={setOpenModal} />
       </>
       )
      }
      <ActivitiesTotal
        miscPresalesExpenses={miscPresalesExpenses}
        subcontractingPresalesExpenses={subcontractingPresalesExpenses}
        intragroupPresalesExpenses={intragroupPresalesExpenses}
        benextPresalesExpenses={benextPresalesExpenses}
      />

      {openModal ? <Modal setOpenModal={setOpenModal} /> : null}

    </ProjectSalesActivitiesStyled>
  )
}
