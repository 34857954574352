import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  InputNumber, InputSearch, InputSelect, InputText, Button,
  Tooltip,
} from '@produits-internes-oss/design-system-components'
import { useDebouncer } from '../../hooks/useDebouncer/useDebouncer'
import { useSearch } from '../../hooks/useSearch/useSearch'
import { useProjectSalesContext } from '../../context/ProjectSale/ProjectSalesContext'
import { ProjectModalStaffingFormStyled } from './ProjectModalStaffingForm.styled'
import { ProjectSelectSkillsActivity } from '../ProjectSelectSkillsActivity/ProjectSelectSkillsActivity'

export const ProjectModalStaffingForm = ({ onClose }) => {
  const { t } = useTranslation()
  const { debounce } = useDebouncer()
  const { fetchSearch } = useSearch()
  const [validateDisabled, setValidateDisabled] = useState(false)
  const {
    fetchCreateActivity,
    fetchPutActivities,
    fetchCreateProjectExpense,
    fetchPutProjectExpenses,
    updateFormStaffing,
    activityType,
    personRole,
    personProfile,
    getPersonName,
    setTurnoverTypeForm,
    turnoverTypeForm,
    updateFormSubcontracting,
    fetchAverageDailyRate,
    turnoverType,
    subcontractor,
    formSubcontracting,
    formStaffing,
    formDefaultValue,
    formSubcontractingError,
    formStaffingError,
    reloadForm,
    setReloadForm,
    project,
    projectSnapshot,
    isProjectPresales,
    modalAddStaffing,
  } = useProjectSalesContext()

  const isEditable = !projectSnapshot || isProjectPresales() || (!isProjectPresales() && modalAddStaffing)

  const SalePrice = useCallback(() => (
    <InputNumber
      className="form-control"
      skin="light"
      name="sale price number input"
      title={t('project.staffing.form.sale_price')}
      localization={{ invalid: { text: 'invalid', arialabel: 'aria label' } }}
      required
      disabled
      valueInitial={formSubcontracting['sale_price'] !== null ? parseInt(formSubcontracting['sale_price'], 10) : null}
      onCheck={() => formSubcontracting['sale_price'] < 0 && 'doit être compléter par un nombre positif  '}
    />
  ), [formSubcontracting['sale_price']])

  const ControlledAverageDailyRate = useCallback(() => (
    <InputNumber
      className="form-control"
      skin="light"
      name="daily_rate number input"
      title={t('project.staffing.form.daily_rate')}
      localization={{ invalid: { text: t('project.staffing.form.error_number'), arialabel: 'aria label' } }}
      required
      disabled
      valueInitial={formStaffing['average_daily_rate']}
      onCheck={() => formStaffing['average_daily_rate'] < 0 && t('project.staffing.form.error_number')}
    />
  ), [formStaffing['average_daily_rate']])

  const errorsFormStaffing = Object.values(formStaffingError).filter(Boolean).length > 0
  const errorsFormSubcontracting = Object.values(formSubcontractingError).filter(Boolean).length > 0

  const initValueNameSubcontracting = () => {
    const idSubcontractorInArray = (personSubocontractor) => personSubocontractor[1].toString() === formSubcontracting['subcontractor_id']
    const indexSubcontractor = subcontractor.findIndex(idSubcontractorInArray)
    return indexSubcontractor
  }
  const initValueRole = () => {
    const idRoleInArray = (role) => role[1] === formStaffing['role_id']
    const indexRole = personRole.findIndex(idRoleInArray)
    return indexRole
  }
  const initProfile = () => {
    const idProfileInArray = (profile) => profile[1] === formStaffing['profile_id']
    const indexProfile = personProfile.findIndex(idProfileInArray)
    return indexProfile
  }

  const isRoleOther = () => {
    const roleOtherIdString = personRole.find(([roleName]) => roleName === 'Autre')?.[1].toString()
    const initialRoleValue = personRole[initValueRole()]

    return (roleOtherIdString === formStaffing['role_id'] || (initialRoleValue && roleOtherIdString === initialRoleValue[1]?.toString()))
  }

  return (
    <ProjectModalStaffingFormStyled
      className="staffing"
      modalAddStaffing={modalAddStaffing}
      title={{ text: `${modalAddStaffing ? '' : t('project.staffing.form.title')}`, level: 2 }}
    >
      <div className="header">
        { turnoverTypeForm || !reloadForm
          ? (
            <InputSelect
              className="form-control"
              skin="light"
              name="activity_type_id select"
              title={t('project.staffing.form.subtitle')}
              options={activityType}
              required={false}
              disabled={!isEditable || !(subcontractor && (subcontractor.length > 0)) || (formStaffing['id'] || formSubcontracting['id'])}
              valueInitial={turnoverType.indexOf(turnoverTypeForm).toString()}
              onChange={([value]) => {
                setTurnoverTypeForm(turnoverType[value])
                setReloadForm(true)
                formDefaultValue(turnoverType[value])
              }}
            />
          ) : null}
        {turnoverTypeForm ? <strong>{t('project.staffing.form.prerequisites')}</strong> : null}
      </div>

      {
        turnoverTypeForm && !reloadForm
          ? (
            <div className="staffing-form">
              {
              turnoverTypeForm === 'from_internal_team'
                ? (
                  <div className="attribute">
                    <InputSelect
                      className="form-control"
                      skin="light"
                      name="role_id select"
                      title={t('project.staffing.form.role')}
                      options={personRole.map((role) => role[0])}
                      required
                      valueInitial={initValueRole().toString()}
                      onChange={([value]) => updateFormStaffing('role_id', (personRole[value][1]).toString())}
                    />
                    {
                    isRoleOther() && (
                      <InputText
                        className="form-control"
                        skin="light"
                        name="title text input"
                        required
                        title={t('project.staffing.form.activity_title')}
                        localization={{ invalid: { text: t('project.staffing.form.error_number'), arialabel: 'aria label' } }}
                        valueInitial={formStaffing['title']}
                        onCheck={() => (!formStaffing['title'] || formStaffing['title'].length === 0) && t('project.staffing.form.error_title_length')}
                        onChange={(value) => updateFormStaffing('title', value.toString())}
                      />
                    )
                  }
                    <InputSelect
                      className="form-control"
                      skin="light"
                      name="profile_id select"
                      title={t('project.staffing.form.level')}
                      options={personProfile.map((profile) => profile[0])}
                      required={false}
                      valueInitial={initProfile().toString()}
                      onChange={([value]) => {
                        fetchAverageDailyRate(
                        project?.mission_maker === 'acn_prime' || project?.mission_maker === 'acn_prime_by_octo',
                        formStaffing['person_id'],
                        (personProfile[value][1]).toString(),
                        )
                        updateFormStaffing('profile_id', (personProfile[value][1]).toString())
                      }}
                    />
                    <Tooltip
                      className="input-search-tooltip"
                      position="top"
                      skin="light"
                      trigger={
                      ({ open, close }) => (
                        <div
                          className="input-search"
                          onMouseEnter={() => formStaffing['total_time_in_days'] && open()}
                          onMouseLeave={() => formStaffing['total_time_in_days'] && close()}
                        >
                          <InputSearch
                            className="form-control"
                            skin="light"
                            name="person search"
                            title={t('project.staffing.form.person_intern')}
                            disabled={formStaffing['total_time_in_days']}
                            required={false}
                            valueInitial={{ key: formStaffing['person_id'], search: formStaffing['person_search'] }}
                            onSearch={(search) => debounce(() => search.length >= 3 && fetchSearch(search, 'Person', null, getPersonName), 500)}
                            onChange={({ key }) => {
                              fetchAverageDailyRate(
                                project?.mission_maker === 'acn_prime' || project?.mission_maker === 'acn_prime_by_octo',
                                key && parseInt(key, 10).toString(),
                                formStaffing['profile_id'],
                              )
                              updateFormStaffing('person_id', key && parseInt(key, 10).toString())
                            }}
                          />
                        </div>
                      )
                    }
                      content={
                      () => (
                        <div>
                          <p>Cette personne a déjà pointé sur cette ligne, vous ne pouvez pas la désaffecter</p>
                        </div>
                      )
                    }
                    />
                    {
                    project?.mission_maker === 'acn_prime' || project?.mission_maker === 'acn_prime_by_octo'
                      ? <ControlledAverageDailyRate />
                      : (
                        <InputNumber
                          className="form-control"
                          skin="light"
                          name="daily_rate number input"
                          title={t('project.staffing.form.daily_rate')}
                          localization={{ invalid: { text: t('project.staffing.form.error_number'), arialabel: 'aria label' } }}
                          required
                          valueInitial={formStaffing['average_daily_rate']}
                          onCheck={() => formStaffing['average_daily_rate'] < 0 && t('project.staffing.form.error_number')}
                          onChange={(value) => updateFormStaffing('average_daily_rate', value.toString())}
                        />
                      )
                  }

                    <InputNumber
                      className="form-control"
                      skin="light"
                      name="nb_days number input"
                      title={t('project.staffing.form.nb_days')}
                      localization={{ invalid: { text: t('project.staffing.form.error_number'), arialabel: 'aria label' } }}
                      required
                      valueInitial={formStaffing['nb_days']}
                      onCheck={() => formStaffing['nb_days'] < 0 && t('project.staffing.form.error_number')}
                      onChange={(value) => updateFormStaffing('nb_days', value.toString())}
                    />
                    <InputText
                      className="form-control"
                      skin="light"
                      name="expertise text input"
                      title={t('project.staffing.form.comment')}
                      localization={{ invalid: { text: t('project.staffing.form.error_number'), arialabel: 'aria label' } }}
                      valueInitial={formStaffing['expertise']}
                      onCheck={() => formStaffing['expertise'] < 0 && t('project.staffing.form.error_number')}
                      onChange={(value) => updateFormStaffing('expertise', value.toString())}
                    />
                    <ProjectSelectSkillsActivity />
                  </div>
                )
                : (
                  <div className="attribute">
                    {turnoverTypeForm === 'intragroup'
                      ? (
                        <InputText
                          className="form-control"
                          skin="light"
                          name="name_last_name text input"
                          title={t('project.staffing.form.name_last_name')}
                          required
                          valueInitial={formSubcontracting['subcontractor_name']}
                          onChange={(value) => updateFormSubcontracting('subcontractor_name', value)}
                        />
                      )
                      : (
                        <InputSelect
                          className="form-control"
                          skin="light"
                          name="role_id select"
                          title={t('project.staffing.form.subcontracting')}
                          options={subcontractor.map((subcontractorData) => subcontractorData[0])}
                          required
                          valueInitial={initValueNameSubcontracting().toString()}
                          onChange={([value]) => updateFormSubcontracting('subcontractor_id', (subcontractor[value][1]).toString())}
                        />
                      )}
                    <InputText
                      className="form-control"
                      skin="light"
                      name="Activité text input"
                      title={t('project.staffing.form.activity')}
                      required
                      valueInitial={formSubcontracting['label']}
                      onChange={(value) => updateFormSubcontracting('label', value)}
                    />
                    <InputNumber
                      className="form-control"
                      skin="light"
                      name="number day number input"
                      title={t('project.staffing.form.quantity_day')}
                      localization={{ invalid: { text: t('project.staffing.form.error_number'), arialabel: 'aria label' } }}
                      required
                      valueInitial={formSubcontracting['quantity']}
                      onCheck={() => formSubcontracting['quantity'] < 0 && t('project.staffing.form.error_number')}
                      onChange={(value) => { updateFormSubcontracting('quantity', value.toString()) }}
                    />
                    {turnoverTypeForm !== 'intragroup'
                  && (
                  <InputText
                    className="form-control"
                    skin="light"
                    name="PurchaseOrder text input"
                    title={t('project.staffing.form.number_po')}
                    required
                    valueInitial={formSubcontracting['purchase_order']}
                    onCheck={() => formSubcontracting['purchase_order'] < 0 && t('project.staffing.form.error_number')}
                    onChange={(value) => { updateFormSubcontracting('purchase_order', value.toString()) }}
                  />
                  )}
                    <InputNumber
                      className="form-control"
                      skin="light"
                      name="unit price number input"
                      title={t('project.staffing.form.unit_price')}
                      localization={{ invalid: { text: t('project.staffing.form.error_number'), arialabel: 'aria label' } }}
                      required
                      valueInitial={formSubcontracting['unit_price']}
                      onCheck={() => formSubcontracting['unit_price'] < 0 && t('project.staffing.form.error_number')}
                      onChange={(value) => {
                        updateFormSubcontracting('unit_price', value.toString())
                        if (formSubcontracting['markup']) {
                          updateFormSubcontracting('sale_price', (value + parseInt(formSubcontracting['markup'], 10)).toString())
                        }
                      }}
                    />
                    <InputNumber
                      className="form-control"
                      skin="light"
                      name="markup number input"
                      title={t('project.staffing.form.markup')}
                      localization={{ invalid: { text: t('project.staffing.form.error_number'), arialabel: 'aria label' } }}
                      required
                      valueInitial={formSubcontracting['markup']}
                      onCheck={() => formSubcontracting['markup'] < 0 && t('project.staffing.form.error_number')}
                      onChange={(value) => {
                        updateFormSubcontracting('markup', value.toString())
                        if (formSubcontracting['unit_price']) {
                          updateFormSubcontracting('sale_price', (parseInt(formSubcontracting['unit_price'], 10) + value).toString())
                        }
                      }}
                    />

                    <SalePrice />
                  </div>
                )
          }
              <div className="footer">
                <div className="control">
                  <Button
                    className="cancel"
                    skin="light"
                    name={t('project.form.cancel.arialabel')}
                    text={t('project.form.cancel.text')}
                    onClick={() => {
                      setTurnoverTypeForm(null)
                      formDefaultValue(null)
                      onClose()
                    }}
                  />
                  <Button
                    className="validate"
                    skin="dark"
                    name="Ajouter"
                    text={(formStaffing['id'] || formSubcontracting['id']) ? t('project.staffing.form.update') : t('project.staffing.form.add')}
                    disabled={validateDisabled || turnoverTypeForm === 'from_internal_team' ? errorsFormStaffing : errorsFormSubcontracting}
                    onClick={async () => {
                      setValidateDisabled(true)
                      if ((formStaffing['id'] || formSubcontracting['id'])) {
                        if (turnoverTypeForm === 'from_internal_team') {
                          await fetchPutActivities([
                            {
                              id: formStaffing['id'],
                              nb_days: formStaffing['nb_days'],
                              role_id: formStaffing['role_id'],
                              title: formStaffing['title'],
                              profile_id: formStaffing['profile_id'],
                              person_ids: [formStaffing['person_id']],
                              average_daily_rate: formStaffing['average_daily_rate'],
                              expertise: formStaffing['expertise'],
                              skill_ids: formStaffing['skill_ids'],
                            },
                          ])
                        } else {
                          await fetchPutProjectExpenses(formSubcontracting)
                        }
                      } else if (turnoverTypeForm === 'from_internal_team') {
                        await fetchCreateActivity()
                      } else {
                        await fetchCreateProjectExpense()
                      }
                      setValidateDisabled(false)
                      setReloadForm(true)
                      setTurnoverTypeForm(null)
                      formDefaultValue(null)
                      onClose()
                    }}
                  />
                </div>
              </div>
            </div>
          )
          : null
      }
    </ProjectModalStaffingFormStyled>
  )
}
