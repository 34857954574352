import React, { useRef } from 'react'
import { cleanWithPunctuation, getFileNameAndExtension } from '../../../../helpers/stringHelper'
import Button from '../Button/Button'
import InputFileStyled from './InputFile.styled'
import pictoAdd from '../../../../../assets/images/add.svg'

export default function InputFile({
  className, name, title, text, error, onChange, onClick, inputRef, inputOnly,
}) {
  const inputUploader = useRef(null)

  return (
    <InputFileStyled className={className}>
      { inputOnly ? ''
        : (
          <Button
            className="trigger"
            name={`${name} input file trigger`}
            title={title}
            text={text}
            picto={pictoAdd}
            error={error}
            style={{ display: 'none' }}
            onClick={onClick || (() => inputUploader.current?.click())}
          />
        ) }
      <input
        ref={inputRef || inputUploader}
        className="uploader"
        aria-label={`${name} input file uploader`}
        type="file"
        accept="image/jpeg,image/png,.pdf"
        capture="filesystem"
        onChange={({ target }) => {
          const file = target.files[0]
          const filename = getFileNameAndExtension(file.name)
          onChange(new File([file], `${cleanWithPunctuation(filename.name)}.${filename.extension}`, {
            type: file.type,
            lastModified: file.lastModified,
          }))
        }}
      />
    </InputFileStyled>
  )
}
